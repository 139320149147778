import axios from "axios";
import { BASE_URL } from "../../variables";

export const getAllSH3EmailsMeetDetails = async ({ emailList }) => {

    const options = {
        url: `${BASE_URL}/services/data/v56.0/query?q=SELECT id,SG__c, Zone__c, Cluster_Name__c,TSI_Territory_Name__c,Town_name__c,Dealer_City__c, Customer_Name__c,Population__c,Objective_of_meet__c,Type_of_DRM__c,Town_Selection_criteria__c,Dealer_Selection_Criteria__c,If_Category_Specific_dealers_then_which__c,No_of_PCC_dealers_in_town__c,Estimated_no_of_Dealers_to_Participate__c,Total_Head_Count_Dealer__c,Total_Head_Count_Employee__c,Current_monthly_average_sales_of_Town__c,Post_DRM_monthly_average_sale_planned__c,Total_Cost__c,Cost_Per_Head__c,Reason_for_Cost__c,    Tentative_date_of_the_meet__c,Actual_Date_of_the_Meet__c,Actual_no_of_Dealers_Participated__c,BM_remarks__c,NSM_Status__c,ZSM_Status__c, NSM_Feedback__c,ZSM_Feedback__c FROM Dealer_Relationship_Meet__c where User_Email__c In (${emailList})`,
        method: "get",
    };
    try {
        const response = await axios(options);
        if (response.status === 200) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};
