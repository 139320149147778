import axios from "axios";
import { BASE_URL } from "../../variables";

export const getEmployeeIdApi = async ( email ) => {

    const options = {
        url: `${BASE_URL}/services/data/v56.0/query?q=select id, Employee_Name__c, Email_ID__c, Employee_Code__c from account where recordtype.name='Employee' and Email_ID__c='${email}' `,
        method: "get",
    };
    try {
        const response = await axios(options);
        if (response.status === 200) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw Error("Something Went Wrong..!!");
        }
    } catch (err) {
        return {
            success: false,
            msg: "Somthing Went Wrong..!!",
        };
    }
};