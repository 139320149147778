//market working Label
export const myWssLabel = [
  {
    heading: "Total",
    heading_second: "WSS Count",
  },
  {
    heading: "Visited",
    heading_second: "WSS Count",
  },
  {
    heading: "Non Visited",
    heading_second: "WSS Count",
  },
  {
    heading: "New",
    heading_second: "WSS Count",
  },
];

export const marketWorkingLabel = [
  {
    heading: "Insight",
    heading_second: "Captured Today"
  },
  {
    heading: "Queries",
    heading_second: "Captured Today"
  },
  {
    heading: "Insight",
    heading_second: "For the month"
  },
  {
    heading: "Queries",
    heading_second: "For the month"
  },
];

//My Dealer Count Label
export const mydealerCountLabel = [
  {
    heading: "Total Validation Requests",
  },
  {
    heading: "Validated",
  },
  {
    heading: "Pending",
  }
];

// tour type options (MTP)
export const tourTypeOption = ["Market Visit", "Office Working"];

// primary objectives (MTP)
export const primaryObjectivesOption = [
  "Dealers Visit",
  "WSS Visit",
  "User Visits/Engagement",
  "Other"
];

// New Feedback Tabs
export const tabs = ["Dealer", "WSS", "Product", "Team"];

// Records type
export const Record = ["Dealer", "WSS"];

// to get Months Name
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Regex mail format
export const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// today date
export const currentDate = new Date()?.toLocaleDateString("fr-CA", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

// cluster Placeholder for Autocomplete
export const clusterCodePlaceHolder = {
  SH3_Name__c: "",
  SH3_Code__c: "",
  Cluster_Description__c: "",
  CLUSTER_CODE__c: "",
};

///////////// DRM Planning Form Constants ////////////////////

export const Quarter = ["Q1", "Q2", "Q3", "Q4"]

export const ObjectiveOfMeet = ["Relationship building with PCC dealers/KD's", "Competition Counteraction", "Dealer Product Training", "Potential Dealer regular batting"]
export const ObjectiveOfMeet301 = ["Relationship building with PCC dealers/KD's", "Competition Counteraction", "Dealer Product Training", "Potential Dealer regular batting", "New Product Launch"]

export const TypeOfDRM = ["Family DRM", "Small Town DRM", "Category Based DRM", "Activity DRM-JJTP,Movie Tickets,Water park,Musical Show,Garba Nights passes etc.", "Sales Linked DRM"]
export const TypeOfDRM301 = ["Family DRM", "Category Based DRM", "Activity DRM-JJTP,Movie Tickets,Water park,Musical Show,Garba Nights passes etc.", "Sales Linked DRM", "Festive DRM"]

export const TownSelectionCriteria = ["Competition impacted markets of PWG(HCM+LCM)", "Competition impacted markets of LW+", "Transferred towns-MRD/STI+", "Category not performing(Category growth< Cluster)", "Relationship with dealers need improvement.", "Competition Impacted Market of MNT"]
export const TownSelectionCriteria301 = ["Markets with new/high competition activity", "Driving Launch of new Product", "To drive LCD engagement", "Category not performing (Category growth < Cluster)", "Relationship with dealers needs improvement.", "To drive PCC engagement"]

export const IfCategorySpecificDealersThenWhichCategory = ["FV major", "CC major", "MNT major", "ASF major", "Mixed Category"]
export const IfCategorySpecificDealersThenWhichCategory301 = ["Fevikwik", "Plumbing", "Cyano", "Fevitite", "Auto Products- RTV,Motomax", "Tapes", "Common"]

export const DealerSelectionCriteria = ["Competition dealers", "Opportunity Dealers like- Roff/Coatings/Sealants/ and so on", "PCC Dealers/Bandhan", "Category Specific dealers"]
export const DealerSelectionCriteria301 = ["Large Competiton dealers", "PCC Dealers", "Opportunity Dealers like- Fevitite/Sealants/RTV/Motomax/WD-40", "Key dealers > 2K monthly purchase of our or competition product.", "Category Specific Dealers", "Dealers approaching KD (> Rs 1.6K per month)"]