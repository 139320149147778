export const msalConfig = {
    // auth: {
    //     clientId: "efd335dd-2e02-4e64-90d7-2e517a389843",
    //     authority: "https://login.microsoftonline.com/fea60e84-eaf3-48a4-90ad-795360fb0878",
    //     redirectUri: "https://mconnect-uat-private-10b5932689b0.herokuapp.com",
    // },
    // auth: {
    //     clientId: "ee9b17fe-4fd4-4ba5-89f6-412513a5b5f3",
    //     authority: "https://login.microsoftonline.com/ca29d8b6-ec6f-470c-aeda-830adb5c1487",
    //     redirectUri: "http://localhost:3000",
    // },
    auth: {
        clientId: "42ffc66e-83a6-41fe-818d-f8a8d29f8501",
        authority: "https://login.microsoftonline.com/fea60e84-eaf3-48a4-90ad-795360fb0878",
        redirectUri: "https://mconnect.pidilite.com",
    },
};

export const loginReq = {
    scopes: ["openid", "profile", "email"]
}
