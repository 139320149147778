import axios from "axios";
import { isLocalServer } from "../Components/Common/checkLocalhost";
import { getNodeServerUrl } from "../utils/getNodeUrl";

export const getSalesforceAccessTokenAPI = async (username) => {
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const nodeBaseUrl = getNodeServerUrl();
    const options = {
        url: `${nodeBaseUrl}/user/get-salesforce-access-token`,
        method: "post",
        headers: headers,
        data: { username },
    };
    try {
        const response = await axios(options);
        return response;
    } catch (error) {
        const errors = error?.response?.data?.error;
        if (errors) {
            return { error: error?.response?.data?.error };
        } else {
            return { error: "Something Went Wrong" };
        }
    }
};