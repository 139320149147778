import { getGlobalAccessTokenApi } from "../../services/getGlobalAccessTokenApi";
import { getPbiAccessTokenApi } from "../../services/getPbiAccessTokenApi";
import { getUserDetailsApi } from "../../services/getUserDetailsApi";
import { loginApi } from "../../services/loginApi";
import { getTerritoryApi } from "../../services/MarketWorking/MyTeam/getTerritoryApi";
import {
  GET_USER_ACCESS_TOKEN_SUCCESS,
  GET_USER_ACCESS_TOKEN_FAIL,
  GET_PCMS_ACCESS_TOKEN_SUCCESS,
  GET_PCMS_ACCESS_TOKEN_FAIL,
  GET_GLOBAL_ACCESS_TOKEN_SUCCESS,
  GET_GLOBAL_ACCESS_TOKEN_FAIL,
  GET_PBI_ACCESS_TOKEN_SUCCESS,
  GET_PBI_ACCESS_TOKEN_FAIL,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  GET_TERRITORY_SUCCESS,
  GET_TERRITORY_FAIL,
  LOGOUT,
  GET_USER_DETAILS_EMPTY,
  SET_SELECTED_INSIGHTS,
  GET_SECONDARY_SALES_ACHIEVED_FAIL,
  GET_BAR_GRAPH_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_USER_TYPE_FAIL,
} from "../constants/action-types";
import { setAlert } from "./alert-action";
import {
  getCheckInDealerDetails,
  getCheckInWSSDetails,
} from "./checkinDetails-actions";
import { getPastSrDetails } from "./pastInsights-actions";
import { getBarGraph, getMarketWorking } from "./homePage-actions";
import { getUpcomingTourPlan } from "./upcomingTourPlan-actions";
import { getFeedbackDetails } from "./getFeedback-action";
import { getDivisionGroup } from "./divisionGroup-action";
import {
  getSecondarySalesAcheived,
  getSecondarySalesAcheivedTrend,
  getSecondarySalesTargetAcheived,
  getSecondarySalesTargetAcheivedTrend,
} from "./secondarySales-actions";
import {
  getTeamsAverageTsiScore,
  getTeamsAverageTsiScoreTrend,
  getTeamsAverageTsrScore,
  getTeamsAverageTsrScoreTrend,
  getTeamsIncentive,
  getTeamsIncentiveTrend,
  getTeamsSop,
  getTeamsSopTrend,
} from "./teams-action";
import {
  getWssAutoVAlueFtd,
  getWssAutoValueFtdTrend,
  getWssCodeForHORDM,
  getTownsForHORDM,
  getWssDarpanSyncDaysException,
  getWssWssInBlock,
  setCheckInFlag,
} from "./wss-actions";
import { getClusterCode } from "./zsm-action";
import { getAsmEncryptKey, getTSIEncryptKey } from "./currentDayTour-action";
import { getTerritoryForHOUserApi } from "../../services/hoUser/getTerritoryForHOUserApi";
import { getSH7CodeHoUserApi } from "../../services/hoUser/getSH7CodeHoUserApi";
import _ from "lodash";
import {
  getOutletsBilledFtm,
  getOutletsBilledFtmTrend,
  getRsmFtm,
  getRsmFtmTrend,
} from "./salesOperation-action";
import { getHoNsmEmail } from "./hoUser-action";
import { getProductNameApi } from "../../services/MarketWorking/MyTeam/getProductNameApi";
import { getUniqueListBy } from "../../Components/Common/Function";
import { getMyUserFeedback } from "./myuser-actions";
import { getRMDMClusterCode } from "./rmdm-action";
import { removeMVRDraftDetails } from "./mvr-action";
import { getBmWss } from "./bms-action";
import { getSalesGroupUserDetails } from "./getSalesGroupUser-action";
import { getRBDMCode } from "./getRBDMCode-action";
import { getUserType } from "./userType-action";
import { getUserTypeApi } from "../../services/getUserTypeApi";
import { getClusterCodeApi } from "../../services/zsm/getClusterCodeApi";
import { getPCMSAccessTokenApi } from "../../services/getPCMSAccessTokenApi ";
import { getSalesforceAccessTokenAPI } from "../../services/getSalesforceAccessTokenAPI";

let count = 0;

export const getGlobalAccessToken = () => async (dispatch) => {
  const response = await getGlobalAccessTokenApi();
  if (response?.data?.success === true) {
    dispatch({
      type: GET_GLOBAL_ACCESS_TOKEN_SUCCESS,
      payload: response?.data?.accessToken,
    });
  } else {
    dispatch({ type: GET_GLOBAL_ACCESS_TOKEN_FAIL });
  }
};

export const getUserAccessToken =
  ({ username, password }) =>
    async (dispatch) => {
      const response = await loginApi({ username, password });
      if (response?.status === 200) {
        getAccessToken(dispatch, response, "").then((status) => {
          dispatch(getUserDetails({ username }));
          dispatch(getBarGraph());
          dispatch(getMarketWorking());
          dispatch(getCheckInDealerDetails());
          dispatch(getCheckInWSSDetails());
          dispatch(getUpcomingTourPlan());
          dispatch(getPastSrDetails());
          dispatch(getFeedbackDetails());
          dispatch(getMyUserFeedback());
        });
      } else {
        dispatch({
          type: GET_USER_ACCESS_TOKEN_FAIL,
        });
        dispatch(setAlert(response?.error, "error"));
      }
    };

export const getSalesforceAccessToken = (username) => async (dispatch) => {
  try {
    const response = await getSalesforceAccessTokenAPI(username);
    if (response?.status === 200) {
      await getAccessToken(dispatch, response, "SSO"); // Assuming this returns a Promise
      dispatch(getUserDetails({ username }));
      dispatch(getBarGraph());
      dispatch(getMarketWorking());
      dispatch(getCheckInDealerDetails());
      dispatch(getCheckInWSSDetails());
      dispatch(getUpcomingTourPlan());
      dispatch(getPastSrDetails());
      dispatch(getFeedbackDetails());
      dispatch(getMyUserFeedback());
      return response; // Return the response
    } else {
      dispatch({ type: GET_USER_ACCESS_TOKEN_FAIL });
      dispatch(setAlert(response?.error, "error"));
      throw new Error("Failed to fetch access token");
    }
  } catch (error) {
    console.error("Error in getSalesforceAccessToken:", error);
    throw error;
  }
};


export const getAccessToken = (dispatch, response, type) => {
  let resPayload = {};
  if (type == "SSO") {
    resPayload = {
      accessToken: response?.data?.access_token,
      isSSOAuthenticated : true
    }
  } else {
    resPayload = response?.data?.accessToken
  }
  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_USER_ACCESS_TOKEN_SUCCESS,
      payload: resPayload,
    });
    resolve("successfully");
  });
};

export const getPbiAccessToken = () => async (dispatch) => {
  const response = await getPbiAccessTokenApi();
  if (response?.data?.success === true) {
    dispatch({
      type: GET_PBI_ACCESS_TOKEN_SUCCESS,
      payload: response?.data?.accessToken,
    });
  } else {
    dispatch({ type: GET_PBI_ACCESS_TOKEN_FAIL });
  }
};

const getPCMSAccessToken = (data) => async (dispatch) => {
  const response = await getPCMSAccessTokenApi(data);
  if (response?.data?.success === true) {
    dispatch({
      type: GET_PCMS_ACCESS_TOKEN_SUCCESS,
      payload: response?.data?.accessToken,
    });
  } else {
    dispatch({ type: GET_PBI_ACCESS_TOKEN_FAIL });
  }
};

export const getUserDetails =
  ({ username }) =>
    async (dispatch, getState) => {
      const response = await getUserDetailsApi({ username });
      if (response?.success === true) {

        // Call to get PCMS access token
        const res = response?.data?.records[0];
        dispatch(getPCMSAccessToken(res));

        getUserDetails1(dispatch, response).then(
          async ([
            SH_Code__c,
            Title,
            Division,
            Sales_Group_Code__c,
            count,
            Email,
            // MConnect_Role__c,
            Id,
          ]) => {
            if (count <= 1) {
              const currentDate = new Date();
              const thirtyDaysAgo = currentDate;
              thirtyDaysAgo.setDate(currentDate.getDate() - 30);
              let draftedForm = getState()?.mvrDraft?.draft?.[Id];
              if (draftedForm?.length) {
                draftedForm = await draftedForm?.filter(
                  (item) => new Date(item?.createdDate) > thirtyDaysAgo
                );
                const drafts = {
                  ...getState()?.mvrDraft?.draft,
                  [Id]: draftedForm,
                };
                dispatch(removeMVRDraftDetails(drafts));
              }

              // To get User Type
              if (Email) {
                const response = await getUserTypeApi(Email);
                if (response?.success === true) {
                  getUserType(dispatch, response).then(async ([HO_Role__c]) => {
                    if (HO_Role__c) {
                      if (HO_Role__c === "RBDM Collaborator") {
                        const res = await getSH7CodeHoUserApi(
                          Division,
                          Sales_Group_Code__c
                        );
                        const Sh7Code = res;
                        dispatch(getTerritory(Sh7Code, Title));
                        dispatch(getRMDMClusterCode(HO_Role__c, Email));
                        dispatch(getProduct(Division, Sales_Group_Code__c));
                        dispatch(
                          getSalesGroupUserDetails(Sales_Group_Code__c, Division)
                        );
                      }

                      if (
                        (HO_Role__c === "MH3" ||
                          HO_Role__c === "RBDM Collaborator") &&
                        Sales_Group_Code__c == "105"
                      ) {
                        dispatch(getRBDMCode(Email));
                      }
                      if (HO_Role__c === "MH3") {
                        const res = await getSH7CodeHoUserApi(
                          Division,
                          Sales_Group_Code__c
                        );
                        const Sh7Code = res;
                        dispatch(getTerritory(Sh7Code, Title));
                        dispatch(getRMDMClusterCode(HO_Role__c, Email));
                        dispatch(getProduct(Division, Sales_Group_Code__c));
                        dispatch(
                          getSalesGroupUserDetails(Sales_Group_Code__c, Division)
                        );
                      }
                      // } else {
                      if (
                        (Title === "HO Collaborator" ||
                          Title === "SH7") &&
                        (!Sales_Group_Code__c || Division == 70 || Division == 30) &&
                        HO_Role__c != "RBDM Collaborator" &&
                        HO_Role__c != "MH3"
                      ) {
                        dispatch(getDivisionGroup());
                      }
                      // to get Dashboard details, territory ,cluster code ,sh7code when user is HO Collaborator (No popup Scenerio)
                      if (
                        (Title === "HO Collaborator" ||
                          Title === "SH7") &&
                        Sales_Group_Code__c !== null &&
                        Division != 70 &&
                        HO_Role__c != "RBDM Collaborator" &&
                        HO_Role__c != "MH3"
                      ) {
                        const res = await getSH7CodeHoUserApi(
                          Division,
                          Sales_Group_Code__c
                        );
                        dispatch(
                          getWssForHoRmDm(
                            res,
                            Title,
                            Division,
                            Sales_Group_Code__c
                          )
                        );
                        dispatch(
                          getWssForHoRmMh(
                            res,
                            Title,
                            Division,
                            Sales_Group_Code__c
                          )
                        );
                        // dispatch(
                        //   getTownForHoRmMh(
                        //     res,
                        //     Title,
                        //     Division,
                        //     Sales_Group_Code__c
                        //   )
                        // );
                        dispatch(getProduct(Division, Sales_Group_Code__c));
                        dispatch(
                          getOutletsBilledFtm(
                            res,
                            Title
                          )
                        );
                        dispatch(
                          getOutletsBilledFtmTrend(
                            res,
                            Title
                          )
                        );
                        dispatch(getRsmFtm(res, Title));
                        dispatch(
                          getRsmFtmTrend(res, Title)
                        );
                        dispatch(
                          getSecondarySalesAcheived(
                            res,
                            Title
                          )
                        );
                        dispatch(
                          getSecondarySalesAcheivedTrend(
                            res,
                            Title
                          )
                        );
                        dispatch(
                          getSecondarySalesTargetAcheived(
                            res,
                            Title
                          )
                        );
                        dispatch(
                          getSecondarySalesTargetAcheivedTrend(
                            res,
                            Title
                          )
                        );
                        dispatch(
                          getTeamsAverageTsiScore(
                            res,
                            Title
                          )
                        );
                        // dispatch(
                        //   getTeamsAverageTsiScoreTrend(res, Title)
                        // );
                        dispatch(
                          getTeamsAverageTsrScore(
                            res,
                            Title
                          )
                        );
                        // dispatch(
                        //   getTeamsAverageTsrScoreTrend(res, Title)
                        // );
                        dispatch(
                          getTeamsIncentive(res, Title)
                        );
                        // dispatch(
                        //   getTeamsIncentiveTrend(res, Title)
                        // );
                        dispatch(
                          getTeamsSop(res, Title)
                        );
                        // dispatch(getTeamsSopTrend(res, Title));
                        dispatch(
                          getWssAutoVAlueFtd(res, Title)
                        );
                        dispatch(
                          getWssAutoValueFtdTrend(
                            res,
                            Title
                          )
                        );
                        dispatch(getWssWssInBlock());
                        dispatch(
                          getWssDarpanSyncDaysException(
                            res,
                            Title
                          )
                        );

                        dispatch(
                          getClusterCode(
                            res,
                            Title,
                            Division,
                            Sales_Group_Code__c
                          )
                        );
                        const data = {
                          divisionGroup: Division,
                          salesGroup: Sales_Group_Code__c,
                        };
                        const { divisionGroup, salesGroup } = data;
                        dispatch(
                          getTerritoryForHOUser({ divisionGroup, salesGroup })
                        );
                        dispatch(getHoNsmEmail(salesGroup));
                      }
                    }
                  });
                } else {
                  dispatch({ type: GET_USER_DETAILS_FAIL });
                }
              }

              if (
                Title === "SH3" ||
                Title === "SH6" ||
                Title === "SH5" ||
                Title === "SH7" ||
                Title === "SH4"
              ) {
                dispatch(getTerritory(SH_Code__c, Title));
                dispatch(getProduct(Division, Sales_Group_Code__c));
                dispatch(getAsmEncryptKey(SH_Code__c));
              }

              //To get Cluster Code when user is (ZSM and NSM)
              if (Title === "SH6" || Title === "SH5" || Title === "SH7" || Title === "SH4") {
                dispatch(getClusterCode(SH_Code__c, Title));
              }

              // if (MConnect_Role__c === "RBDM Collaborator") {
              //   dispatch(getRMDMClusterCode("RBDM Collaborator", Email));
              //   dispatch(getProduct(Division, Sales_Group_Code__c));
              //   dispatch(getSalesGroupUserDetails(Sales_Group_Code__c, Division));
              // }

              // if ((Title === "MH3" || MConnect_Role__c === "RBDM Collaborator") && Sales_Group_Code__c == '105') {
              //   dispatch(getRBDMCode(Email));
              // }

              // To get Dashboard details when user is (BM ,ZSM and NSM)
              if (
                Title === "SH3" ||
                (Title === "SH6" ||
                  Title === "SH5" ||
                  Title === "SH7" ||
                  Title === "SH4") && Division != 70
              ) {
                dispatch(getSecondarySalesAcheived(SH_Code__c, Title));
                dispatch(getSecondarySalesAcheivedTrend(SH_Code__c, Title));
                dispatch(getSecondarySalesTargetAcheived(SH_Code__c, Title));
                dispatch(getSecondarySalesTargetAcheivedTrend(SH_Code__c, Title));
                dispatch(getTeamsAverageTsiScore(SH_Code__c, Title));
                // dispatch(getTeamsAverageTsiScoreTrend(SH_Code__c, Title));
                dispatch(getTeamsAverageTsrScore(SH_Code__c, Title));
                // dispatch(getTeamsAverageTsrScoreTrend(SH_Code__c, Title));
                dispatch(getTeamsIncentive(SH_Code__c, Title));
                // dispatch(getTeamsIncentiveTrend(SH_Code__c, Title));
                dispatch(getTeamsSop(SH_Code__c, Title));
                // dispatch(getTeamsSopTrend(SH_Code__c, Title));
                dispatch(getWssAutoVAlueFtd(SH_Code__c, Title));
                dispatch(getWssAutoValueFtdTrend(SH_Code__c, Title));
                dispatch(getWssWssInBlock());
                dispatch(getWssDarpanSyncDaysException(SH_Code__c, Title));
                dispatch(getOutletsBilledFtm(SH_Code__c, Title));
                dispatch(getOutletsBilledFtmTrend(SH_Code__c, Title));
                dispatch(getRsmFtm(SH_Code__c, Title));
                dispatch(getRsmFtmTrend(SH_Code__c, Title));
              }

              if (Title === "SH3") {
                dispatch(setCheckInFlag(true));
              }
            }
          }
        );
      } else {
        sessionExpireTimeout(dispatch);
        dispatch({ type: GET_USER_DETAILS_FAIL });
      }
    };

export const logout =
  (isUpdated = false, version) =>
    async (dispatch) => {
      count = 0;
      dispatch({ type: LOGOUT });
      dispatch({ type: GET_USER_DETAILS_EMPTY });
      dispatch({ type: GET_SECONDARY_SALES_ACHIEVED_FAIL });
      dispatch({ type: GET_BAR_GRAPH_FAIL });
      dispatch({ type: GET_USER_TYPE_FAIL });
      if (isUpdated) {
        dispatch(
          setAlert(
            `App updated to version ${version} ,Please Login again`,
            "error"
          )
        );
      }
    };

const getUserDetails1 = (dispatch, response) => {
  if (
    response?.data?.records?.[0]?.Profile?.Name === "HO Colloaborator" ||
    response?.data?.records?.[0]?.Profile?.Name === "CWP Colloaborator"
  ) {
    response.data.records[0].Title = "HO Collaborator";
  }
  ++count;
  const data = { ...response?.data?.records?.[0], count: count };
  return new Promise((resolve, reject) => {
    dispatch({
      type: GET_USER_DETAILS_SUCCESS,
      payload: data,
    });

    resolve([
      response?.data?.records?.[0]?.SH_Code__c,
      response?.data?.records?.[0]?.Title,
      response?.data?.records?.[0]?.Division,
      response?.data?.records?.[0]?.Sales_Group_Code__c,
      count,
      response?.data?.records?.[0]?.Email,
      // response?.data?.records?.[0]?.MConnect_Role__c,
      response?.data?.records?.[0]?.Id,
    ]);
  });
};

// Territory for non-Ho user
export const getTerritory = (UserSH_Code__c, Title) => async (dispatch) => {
  const response = await getTerritoryApi({ UserSH_Code__c, Title });
  if (response?.success === true) {
    if (Title === "SH3") {
      dispatch(getBmWss(response?.data?.records));
    }
    dispatch({
      type: GET_TERRITORY_SUCCESS,
      payload: response?.data?.records,
    });
  } else {
    dispatch({ type: GET_TERRITORY_FAIL });
  }
};

export const getSH7Code = (UserSH_Code__c, Title) => async (dispatch) => {
  const response = await getSH7CodeHoUserApi({ UserSH_Code__c, Title });
  if (response?.success === true) {
    dispatch({
      type: GET_TERRITORY_SUCCESS,
      payload: response?.data?.records,
    });
  } else {
    dispatch({ type: GET_TERRITORY_FAIL });
  }
};

export const getWssForHoRmMh = (sh7code, Title, division, salesGroupCode) => async (dispatch) => {
  var code = sh7code;
  const clusterRes = await getClusterCodeApi(code, Title, division, salesGroupCode);
  if (clusterRes?.success === true) {
    dispatch(getWssCodeForHORDM(clusterRes));
  }
};

export const getTownForHoRmMh = (sh7code, Title, division, salesGroupCode) => async (dispatch) => {
  var code = sh7code;
  const clusterRes = await getClusterCodeApi(code, Title, division, salesGroupCode);
  if (clusterRes?.success === true) {
    dispatch(getTownsForHORDM(clusterRes));
  }
};

export const getWssForHoRmDm = (Title, Email) => async (dispatch) => {
  const clusterRes = await getClusterCodeApi("", Title, "", "", Email);
  if (clusterRes?.success === true) {
    dispatch(getWssCodeForHORDM(clusterRes));
  }
};

// Territory for Ho user
export const getTerritoryForHOUser =
  ({ divisionGroup, salesGroup }) =>
    async (dispatch) => {
      const response = await getTerritoryForHOUserApi({
        divisionGroup,
        salesGroup,
      });
      if (response?.status === 200) {
        dispatch({
          type: GET_TERRITORY_SUCCESS,
          payload: response?.data?.records,
        });
      } else {
        dispatch({ type: GET_TERRITORY_FAIL });
      }
    };

// product for BM
export const getProduct = (divisionGroup, salesGroup) => async (dispatch) => {
  const response = await getProductNameApi(divisionGroup, salesGroup);
  if (response?.status === 200) {
    dispatch({
      type: GET_PRODUCT_SUCCESS,
      payload: getUniqueListBy(
        response?.data?.records,
        "Product_Group_3_Description__c"
      ),
    });
  } else {
    dispatch({ type: GET_PRODUCT_FAIL });
  }
};

export const sessionExpireTimeout = (dispatch) => {
  sessionExpire(dispatch).then((status) => {
    dispatch(setAlert("Session Expired ,Please Login again", "error"));
  });
};

const sessionExpire = (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(logout());
    resolve("successfully");
  });
};

export const updateSelectedInsights = (insights) => {
  return {
    type: SET_SELECTED_INSIGHTS,
    payload: insights,
  };
};
