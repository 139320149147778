import React from "react";
import { useSelector } from "react-redux";
import { PCMS_URL } from "../../variables";
import { useParams } from 'react-router-dom';

const PCMSDashboard = () => {

    const { id } = useParams();
    const pcmsToken = useSelector((state) => state?.pcmsToken?.pcmsAccessToken);
    console.log("ID", id)

    //---------------------------------------- Get URL With token ----------------------------------------------------- //
    
    const getIframeSrc = () => {
        return id
            ? `${PCMS_URL}/create?token=${pcmsToken}&id=${id}`
            : `${PCMS_URL}/?token=${pcmsToken}`;
    };

    //---------------------------------------------- End ------------------------------------------------------------- //

    return (
        <>
            <div className="pcms__outerBox">
                <iframe
                    id="embeddedIframe"
                    className="pcms__iframe"
                    src={getIframeSrc()}
                    title="Embedded Iframe"
                ></iframe>
            </div>
        </>
    );
};

export default PCMSDashboard;
