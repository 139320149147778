import React, { useState, useEffect, useCallback } from 'react';
import PageHeader from '../Common/PageHeader';
import Loader from '../Common/Loader';
import "./DRMDetails.css"
import _ from "lodash";
import { useLocation, useParams } from "react-router-dom";

function DRMDetails() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const location = useLocation();
    const meetingDetails = location.state;

    return (
        <div>
            <Loader loading={loading} />
            <PageHeader title="DRM Meeting Details" path="/drm-meeting-approval" />
            <>
                <div className='drmDetails__OuterBox'>
                    <div className='drmDetails__innerBox'>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Zone: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Zone__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Town Name: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Town_name__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>TSI Territory Name: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.TSI_Territory_Name__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Cluster Name: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Cluster_Name__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Customer Name: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Customer_Name__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Objective of meet: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Objective_of_meet__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Type of DRM: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Type_of_DRM__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Town Selection criteria: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Town_Selection_criteria__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Dealer Selection Criteria: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Dealer_Selection_Criteria__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>If Category Specific dealers then which: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.If_Category_Specific_dealers_then_which__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>No of PCC dealers in town: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.No_of_PCC_dealers_in_town__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Estimated no of Dealers to Participate: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Estimated_no_of_Dealers_to_Participate__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Dealer Head Count: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Total_Head_Count_Dealer__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Employee Head Count: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Total_Head_Count_Employee__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Total Cost: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Total_Cost__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Cost Per Head: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Cost_Per_Head__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Tentative date of the meet: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Tentative_date_of_the_meet__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Current monthly average sales of Town: </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Current_monthly_average_sales_of_Town__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>Post DRM monthly average sale planned : </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.Post_DRM_monthly_average_sale_planned__c}</div>
                        </div>
                        <div className='drmDetails__despBox'>
                            <div className='drmDetails__despBoxName'>ZSM Status :  </div>
                            <div className='drmDetails__despBoxValue'>{meetingDetails.ZSM_Status__c}</div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}
export default DRMDetails;