import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import PrivateRoute from "./Components/Routing/PrivateRoute";
import setAuthToken from "./services/setAuthToken";
import Login from "./Components/Login";
import Homepage from "./Pages/Homepage";
import MonthlyTourPlan from "./Pages/MonthlyTourPlan";
import DRMplanning from "./Pages/DRMplanning";
import FeedbackForm from "./Components/DRMPlanning/FeedbackForm";
import MyDealer from "./Pages/MyDealer";
import MyTeam from "./Pages/MyTeam";
import MyDiary from "./Pages/MyDiary";
import DealerLocator from "./Pages/DealerLocator";
import BottomNavbar from "./Components/BottomNavbar";
import PbiDashboard from "./Components/MonthlyTourPlan/PbiDashboard";
import KeyInformation from "./Pages/KeyInformation";
import CurrentDayTour from "./Pages/CurrentDayTour";
import TotalFeedback from "./Components/MarketWorking/MyDiary/TotalFeedback";
import MarketWorking from "./Pages/MarketWorking";
import Queries from "./Pages/Queries";
import ExceptionDealer from "./Pages/ExceptionDealer";
import TourReport from "./Pages/TourReport";
import CWP from "./Pages/CWP";
import Survey from "./Pages/Survey";
import PowerBi from "./Pages/PowerBi";
import PastSrInsights from "./Components/MarketWorking/PastSrInsights";
import QueryDetails from "./Components/Queries/QueryDetails";
import MarketVisitReport from "./Pages/MarketVisitReport";
import Alert from "./Components/Common/Alert";
import DivisionGroup from "./Components/HomePage/DivisionGroup";
import OnlineStatus from "./Components/Common/OnlineStatus";
import MyUser from "./Pages/MyUser";
import packageJson from "../package.json";
import { logout, sessionExpireTimeout } from "./Redux/actions/utility-actions";
import MyProfile from "./Pages/MyProfile";
import MyWssPage from "./Pages/MyWssPage";
import PILSuervey from "./Components/PILSurvey/PILSuervey";
import PILSurveyPage from "./Pages/PILSurveyPage";
import DRM from "./Pages/DRM";
import PCMS from "./Pages/PCMS";
import TSIMarketWorking from "./Components/PowerBi/TSIMarketWorking";
import KeyDealerPerformance from "./Components/PowerBi/KeyDealerPerformance";
import DistributorInvoicing from "./Components/PowerBi/DistributorInvoicing";
import { useIsAuthenticated } from '@azure/msal-react';
import BranchPerformanceIndex from "./Components/PowerBi/BranchPerformanceIndex";
import DRMDetails from "./Components/DRMDetails/DRMDetails";
import DRMMeetingApproval from "./Components/DRMDetails/DRMMeetingApproval";

function App({ logout }) {

  const dispatch = useDispatch();
  const [time, setTime] = useState('');
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const [showRemainTime, setShowRemainTime] = useState(false);

  let currentVersion = localStorage.getItem("mconnectVersion");
  if (currentVersion) {
    if (currentVersion < packageJson.version) {
      localStorage.setItem("mconnectVersion", packageJson.version);
      logout(true, packageJson.version);
    }
  } else {
    localStorage.setItem("mconnectVersion", packageJson.version);
    logout(true, packageJson.version);
  }
  const accessToken = useSelector((state) => state?.tokens?.accessToken);
  const isAuthenticated = useSelector((state) => state?.tokens?.isAuthenticated);
  const isSessionOut = useSelector((state) => state?.sessionAlert?.Alert);
  const isSSOAuthenticated = useIsAuthenticated();

  if (accessToken) {
    setAuthToken(accessToken);
  }

  const calculateRemainingTime = () => {
    const now = Math.floor(Date.now() / 1000); // Current time in seconds
    const creationDate = Math.floor(new Date(expiryTime).getTime() / 1000); // Creation time in seconds
    const endTime = creationDate + 15 * 60;
    const remainingTime = Math.max(0, endTime - now); // in seconds
    setTime(remainingTime)
    return remainingTime;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 15 ? '0' : ''}${minutes}:${remainingSeconds < 15 ? '0' : ''}${remainingSeconds}`;
  };

  useEffect(() => {
    calculateRemainingTime()
    if (isSessionOut) {
      setShowRemainTime(true)
      if (time === 0) {
        setShowRemainTime(false)
        sessionExpireTimeout(dispatch);
        return;
      }
      const intervalId = setInterval(() => {
        setTime(calculateRemainingTime());
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isSessionOut, time])

  return (
    <>
      <Alert />
      {showRemainTime == true &&
        <div className="SessionOut_Alert">
          Your session will expire in {formatTime(time)}
        </div>
      }
      <Routes>
        <Route
          path="/login"
          exact
          element={<PrivateRoute component={Login} />}
        />
        <Route path="/" element={<PrivateRoute component={Homepage} />} />
        <Route
          path="/division-group"
          exact
          element={<PrivateRoute component={DivisionGroup} />}
        />
        <Route
          path="/monthly-tour-plan"
          exact
          element={<PrivateRoute component={MonthlyTourPlan} />}
        />
        <Route
          path="/DRM-Summary"
          exact
          element={<PrivateRoute component={DRM} />}
        />


        {/* <Route
          path="/DRMList"
          exact
          element={<PrivateRoute component={DRMList} />}
        /> */}
        <Route
          path="/my-dealer"
          exact
          element={<PrivateRoute component={MyDealer} />}
        />
        <Route
          path="/queries"
          exact
          element={<PrivateRoute component={Queries} />}
        />

        <Route
          path="/my-diary"
          exact
          element={<PrivateRoute component={MyDiary} />}
        />
        <Route
          path="/my-team"
          exact
          element={<PrivateRoute component={MyTeam} />}
        />

        <Route
          path="/dealer-locator"
          exact
          element={<PrivateRoute component={DealerLocator} />}
        />

        <Route
          path="/pbi-dashboard"
          exact
          element={<PrivateRoute component={PbiDashboard} />}
        />
        <Route
          path="/current-day-tour"
          exact
          element={<PrivateRoute component={CurrentDayTour} />}
        />
        <Route
          path="/bottom-navbar"
          exact
          element={<PrivateRoute component={BottomNavbar} />}
        />
        <Route
          path="/key-information"
          exact
          element={<PrivateRoute component={KeyInformation} />}
        />
        <Route
          path="/total-feedback"
          exact
          element={<PrivateRoute component={TotalFeedback} />}
        />
        <Route
          path="/market-working"
          exact
          element={<PrivateRoute component={MarketWorking} />}
        />
        {/* <Route
          path="/tour-report"
          exact
          element={<PrivateRoute component={TourReport} />}
        /> */}

        <Route
          path="/exception-dealer"
          exact
          element={<PrivateRoute component={ExceptionDealer} />}
        />

        <Route
          path="/survey"
          exact
          element={<PrivateRoute component={Survey} />}
        />
        <Route path="/cwp" exact element={<PrivateRoute component={CWP} />} />
        <Route
          path="/power-bi"
          exact
          element={<PrivateRoute component={PowerBi} />}
        />
        <Route
          path="/past-sr-insight"
          exact
          element={<PrivateRoute component={PastSrInsights} />}
        />
        <Route
          path="/query-details/:id"
          exact
          element={<PrivateRoute component={QueryDetails} />}
        />
        <Route
          path="/market-visit-report"
          exact
          element={<PrivateRoute component={MarketVisitReport} />}
        />
        <Route
          path="/my-user"
          exact
          element={<PrivateRoute component={MyUser} />}
        />
        <Route
          path="/my-profile"
          exact
          element={<PrivateRoute component={MyProfile} />}
        />
        <Route
          path="/my-wss"
          exact
          element={<PrivateRoute component={MyWssPage} />}
        />
        <Route
          path="/pil-Surveys"
          exact
          element={<PrivateRoute component={PILSurveyPage} />}
        />
        <Route
          path="/DRM-Summary"
          exact
          element={<PrivateRoute component={DRM} />}
        />
        <Route
          path="/DRMplanning"
          exact
          element={<PrivateRoute component={DRMplanning} />}
        />
        <Route
          path="/PCMS/:id"
          exact
          element={<PrivateRoute component={PCMS} />}
        />
        <Route
          path="/PCMS"
          exact
          element={<PrivateRoute component={PCMS} />}
        />
        <Route
          path="/branch-performance-index"
          exact
          element={<PrivateRoute component={BranchPerformanceIndex} />}
        />
        <Route
          path="/tsi-market-working"
          exact
          element={<PrivateRoute component={TSIMarketWorking} />}
        />
        <Route
          path="/distributor-invoicing"
          exact
          element={<PrivateRoute component={DistributorInvoicing} />}
        />
        <Route
          path="/key-dealer-performance"
          exact
          element={<PrivateRoute component={KeyDealerPerformance} />}
        />
        <Route
          path="/drm-details/:id"
          exact
          element={<PrivateRoute component={DRMDetails} />}
        />
        <Route
          path="/drm-meeting-approval"
          exact
          element={<PrivateRoute component={DRMMeetingApproval} />}
        />
        <Route path="/DRMfeedbackForm" exact element={<FeedbackForm />} />
      </Routes>
      {/* {isAuthenticated && <BottomNavbar />} */}
      {(isSSOAuthenticated || isAuthenticated) && <BottomNavbar />}

      <OnlineStatus />
    </>
  );
}

export default connect(null, { logout })(App);
