import axios from "axios";
import { BASE_URL } from "../../variables";

export const getAllSH3Emails = async ({ userEmailid, userRole }) => {
    try {
        let url = "";

        if (userRole === "SH7") {
            url = `${BASE_URL}/services/data/v56.0/query?q=SELECT SH3_Email__c FROM Pidilite_Hierarchy__c WHERE SH7_Email__c = '${userEmailid}' AND RecordType.Name = 'Sales Hierarchy' GROUP BY SH3_Email__c`;
        } else {
            url = `${BASE_URL}/services/data/v56.0/query?q=SELECT SH3_Email__c FROM Pidilite_Hierarchy__c WHERE SH5_Email__c = '${userEmailid}' AND RecordType.Name = 'Sales Hierarchy' GROUP BY SH3_Email__c`;
        }

        const response = await axios.get(url);

        if (response.status === 200) {
            return {
                success: true,
                data: response?.data,
            };
        } else {
            throw new Error("Something Went Wrong..!!");
        }
    } catch (err) {
        console.error("Error fetching SH3 Emails:", err);
        return {
            success: false,
            msg: "Something Went Wrong..!!",
        };
    }
};
