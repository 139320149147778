import React, { useState, useEffect, useCallback } from 'react';
import PageHeader from '../Common/PageHeader';
import { Accordion, Button, Form } from 'react-bootstrap';
import { getAllSH3Emails } from '../../services/DRM/getSH3Email';
import { getAllSH3EmailsMeetDetails } from '../../services/DRM/getMeetingDetailsByEmail';
import Loader from '../Common/Loader';
import { useSelector } from 'react-redux';
import { addMeetingApprovalApi } from '../../services/DRM/addMeetingApproval';
import "./DRMDetails.css"
import styles from "./DrmMeetingApproval.module.css";
import _ from "lodash";
//import { LuCircleArrowRight } from "react-icons/lu";
import { SlArrowRightCircle } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import ModalPopup from "../Common/ModalPopup";
import Message from '../Common/Message';

const DRMMeetingApproval = () => {

    const [loading, setLoading] = useState(false);
    const [meetingDetails, setMeetingDetails] = useState([]);
    const [remarksError, setRemarksError] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [selectedMeetingId, setSelectedMeetingId] = useState(null);

    const userRole = useSelector(state =>
        state?.userType?.type !== "Other" ? state?.userType?.type : state?.userDetails?.details?.Title
    );

    const userEmailid = useSelector(state => state?.userDetails?.details?.Email
    );
    const fetchEmailsAndMeetings = useCallback(async () => {
        setLoading(true);
        try {
            const emailResponse = await getAllSH3Emails({ userEmailid, userRole });

            if (emailResponse.success && emailResponse.data) {
                const extractedEmails = emailResponse?.data?.records?.map(item => item.SH3_Email__c) || [];

                if (extractedEmails.length > 0) {
                    const formattedEmails = extractedEmails.map(email => `'${email}'`).join(",");
                    const meetingResponse = await getAllSH3EmailsMeetDetails({ emailList: formattedEmails });

                    if (meetingResponse.success && Array.isArray(meetingResponse.data.records)) {
                        setMeetingDetails(meetingResponse.data.records);
                    } else {
                        setMeetingDetails([]);
                    }
                } else {
                    setMeetingDetails([]);
                }
            }
        } catch (error) {
            console.error("Error fetching emails or meetings:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchEmailsAndMeetings();
    }, [fetchEmailsAndMeetings]);

    const navigate = useNavigate();

    const handleClick = (item) => {
        console.log("data", item);
        navigate(`/drm-details/${item.Id}`, { state: item });
    };

    const reject = (meetingId) => {
        setSelectedMeetingId(meetingId);
        setIsRejectModalOpen(true);
    };

    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
        setRemarksError(false);
    };

    const handleSubmitRemark = () => {
        if (remarks.trim() === '') {
            setRemarksError(true);
            return;
        } else {
            handleAction(selectedMeetingId, 'Reject');
            setLoading(true);
            setIsRejectModalOpen(false);
            setRemarks("");
        }
    };

    const handleAction = async (meetingId, action) => {

        let payload = [
            {
                Id: meetingId,
                ZSM_Status: "",
                NSM_Status: "",
                ZSM_Feedback: "",
                NSM_Feedback: ""
            }
        ];

        // Action logic based on userRole and action type
        if (userRole === "SH5" || userRole === "SH6") {
            if (action === "Approve") {
                payload[0].ZSM_Status = "Approved";
                payload[0].NSM_Status = "Pending";
                // payload[0].ZSM_Feedback = comments[meetingId];
            } else if (action === "Reject") {
                payload[0].ZSM_Status = "Rejected";
                payload[0].NSM_Status = "Pending";
                payload[0].ZSM_Feedback = remarks;
            }
        } else if (userRole === "SH7") {
            if (action === "Approve") {
                payload[0].ZSM_Status = "Approved";
                payload[0].NSM_Status = "Approved";
                // payload[0].NSM_Feedback = comments[meetingId];
            } else if (action === "Reject") {
                payload[0].ZSM_Status = "Approved";
                payload[0].NSM_Status = "Rejected";
                payload[0].NSM_Feedback = remarks;
            }
        }

        try {
            setLoading(true);
            const response = await addMeetingApprovalApi(payload);
            if (response.success) {
                fetchEmailsAndMeetings();
            } else {
                alert(`Failed to ${action} meeting`);
            }
        } catch (error) {
            console.error("Approval API Error:", error);
            alert("Error processing request");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Loader loading={loading} />
            <PageHeader title="DRM Meeting Approval" path="/" />
            <>
                <div className='row m-0 g-0 d-flex'>
                    {meetingDetails?.filter(item =>
                        (userRole === 'SH7' && item.ZSM_Status__c === 'Approved' && item?.NSM_Status__c === 'Pending') ||
                        ((userRole === 'SH5' || userRole === 'SH6') && (item?.ZSM_Status__c === 'Pending'))
                    ).length > 0 ? (
                        meetingDetails
                            ?.filter(item =>
                                (userRole === 'SH7' && item.ZSM_Status__c === 'Approved' && item?.NSM_Status__c === 'Pending') ||
                                ((userRole === 'SH5' || userRole === 'SH6') && (item?.ZSM_Status__c === 'Pending'))
                            )
                            .map((item, index) => (
                                <div key={index} className={`col-md-6 col-lg-4 ${styles.drmMeetingApproval}`}>
                                    <div className={styles.drmMeetingApproval__mainBox}>
                                        <div className="row m-0 p-0">
                                            <div className={`col-12 ${styles.drmMeetingApproval__rightBox}`}>
                                                <div className="row">
                                                    <div className="col-10">
                                                        <h5 className={styles.drmMeetingApproval__title}>
                                                            Meeting ID - {item?.Id}
                                                        </h5>
                                                    </div>
                                                    <div className={`col-2 align-content-center text-center`}>
                                                        <SlArrowRightCircle className={styles.drmMeetingApproval__redirectArrow}
                                                            onClick={() => handleClick(item)} />
                                                    </div>
                                                </div>
                                                <p className={styles.drmMeetingApproval__desp}>
                                                    Tentative Date :{" "}
                                                    {_.isEmpty(item?.Tentative_date_of_the_meet__c)
                                                        ? "----"
                                                        : item?.Tentative_date_of_the_meet__c}
                                                </p>
                                                <hr className='m-1' />
                                                <div className={styles.drmMeetingApproval__despRow}>
                                                    <div className={styles.drmMeetingApproval__despBox}>
                                                        <div className={styles.drmMeetingApproval__despBoxName}>
                                                            Cluster Name :
                                                        </div>
                                                        <div className={styles.drmMeetingApproval__despBoxValue}>
                                                            {item.Cluster_Name__c}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.drmMeetingApproval__despRow}>
                                                    <div className={styles.drmMeetingApproval__despBox}>
                                                        <div className={styles.drmMeetingApproval__despBoxName}>
                                                            Town Name :
                                                        </div>
                                                        <div className={styles.drmMeetingApproval__despBoxValue}>
                                                            {item.Town_name__c}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.drmMeetingApproval__despRow}>
                                                    <div className={styles.drmMeetingApproval__despBox}>
                                                        <div className={styles.drmMeetingApproval__despBoxName}>
                                                            Cost per Head :
                                                        </div>
                                                        <div className={styles.drmMeetingApproval__despBoxValue}>
                                                            <strong>{item.Cost_Per_Head__c}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.drmMeetingApproval__despRow}>
                                                    <div className={styles.drmMeetingApproval__despBox}>
                                                        <div className={styles.drmMeetingApproval__despBoxName}>
                                                            Type of drm :
                                                        </div>
                                                        <div className={styles.drmMeetingApproval__despBoxValue}>
                                                            {item?.Type_of_DRM__c}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.drmMeetingApproval__despRow}>
                                                    <div className={styles.drmMeetingApproval__despBox}>
                                                        <div className={styles.drmMeetingApproval__despBoxName}>
                                                            Estimated No of dealer Participents :
                                                        </div>
                                                        <div className={styles.drmMeetingApproval__despBoxValue}>
                                                            {item.Estimated_no_of_Dealers_to_Participate__c}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.drmMeetingApproval__despRow}>
                                                    <div className={styles.drmMeetingApproval__buttonBox}>
                                                        <button className={styles.drmMeetingApproval__approveButton} onClick={() => { handleAction(item?.Id, 'Approve') }}>
                                                            Approve
                                                        </button>
                                                        <button className={styles.drmMeetingApproval__rejectButton} onClick={() => { reject(item?.Id, 'Reject') }}>
                                                            Rework
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                    ) : (
                        <Message
                            message={"No data found"}
                            className={"tourPlan__messageConatiner"}
                        />
                    )}
                </div>


                {isRejectModalOpen && (
                    <ModalPopup
                        className="ModalPopup_Submitted"
                        isModalOpen={isRejectModalOpen}
                        ModalMainClass="MyDealerSummaryModal"
                        setIsModalOpen={setIsRejectModalOpen}
                        data={
                            <>
                                <div className={`${styles.drmMeetingApproval__rejectTitle}`}>
                                    Do you want to rework ?
                                </div>
                                <div className="col-12 d-grid">
                                    <label className={styles.drmMeetingApproval__rejectLabel}
                                        style={{ whiteSpace: "nowrap" }}> Remarks </label>
                                    <textarea className={styles.drmMeetingApproval__remarkBox}
                                        value={remarks} onChange={handleRemarksChange}></textarea>
                                    {remarksError && (
                                        <div className={styles.drmMeetingApproval__remarkError}>
                                            Remarks cannot be empty.
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                        backdrop={true}
                        fade={false}
                        showButton={
                            <>
                                <Loader loading={loading} />
                                <div className={styles.drmMeetingApproval__saveBox}>
                                    <button className={styles.drmMeetingApproval__cancel} onClick={() => { setIsRejectModalOpen(false) }}> cancel </button>
                                    <button className={styles.drmMeetingApproval__submit} onClick={() => { handleSubmitRemark() }}> Submit </button>
                                </div>
                            </>
                        }
                    />
                )}
            </>
        </>
    )
}

export default DRMMeetingApproval