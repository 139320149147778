import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalPopup from "./Common/ModalPopup";
import "../Components/Login.css";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  getUserAccessToken,
  getGlobalAccessToken,
  getAccessToken,
  getSalesforceAccessToken,
} from "../Redux/actions/utility-actions";
import { forgotPasswordApi } from "../services/forgotPasswordApi";
import _ from "lodash";
import { BsCheckCircle } from "react-icons/bs";
import { BiLogInCircle } from "react-icons/bi";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Loader from "./Common/Loader";
import CommonSnackbar from "./Common/CommonSnackbar";
import { GET_GLOBAL_ACCESS_TOKEN_FAIL } from "../Redux/constants/action-types";
import { mailformat } from "./Common/Constant";
import packageJson from "./../../package.json";
import { useMsal } from "@azure/msal-react";
import { loginReq } from "../authConfig";
import { FaWindows } from "react-icons/fa";

const Login = ({
  getUserAccessToken,
  isAuthenticated,
  getGlobalAccessToken,
  globalAccessToken,
  accessToken,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [emailId, setEmailId] = useState("");
  const [error, setError] = useState();
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [failedOpen, setFailesOpen] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [isResetClicked, setIsResetClicked] = useState(false);
  const appVersion = packageJson.version;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (info, e) => {
    e.preventDefault();
    const data = {
      username: info?.email,
      password: info?.password,
    };
    const { username, password } = data;
    setLoading(true);
    await getUserAccessToken({ username, password });
    setLoading(false);
    reset();
  };

  useEffect(() => {
    if (isAuthenticated && !_.isEmpty(accessToken)) {
      navigate("/");
    }
  }, [isAuthenticated, accessToken]);

  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
  };

  const onReset = async (e) => {
    e.preventDefault();
    setIsResetClicked(true);
    setFormValidation(checkValidation(emailId));
  };

  // validation to check emailId
  const checkValidation = (value) => {
    const errors = {};
    if (!value) {
      errors.email = "Email Id is required";
    }
    if (value && !value.match(mailformat)) {
      errors.email = "Please enter valid email Address";
    }
    return errors;
  };

  //global access Token api call on click of reset password button
  useEffect(() => {
    if (Object?.keys(formValidation)?.length === 0 && isResetClicked) {
      (async () => {
        setLoading(true);
        setShow(false);
        await getGlobalAccessToken();
        setIsResetClicked(false);
      })();
    }
    if (Object?.keys(formValidation)?.length !== 0) {
      setIsResetClicked(false);
    }
  }, [isResetClicked]);

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(globalAccessToken)) {
        const response = await forgotPasswordApi({
          globalAccessToken,
          emailId,
        });
        setLoading(false);
        dispatch({ type: GET_GLOBAL_ACCESS_TOKEN_FAIL });
        if (response?.success == true) {
          setIsResetPassword(true);
          navigate("/");
          setFormValidation({});
          setEmailId("");
        } else {
          setIsResetPassword(false);
          setFailesOpen(true);
          setError(response?.data);
        }
      }
    })();
  }, [globalAccessToken]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  //---------------------------------------- SSO Integration ------------------------------------------//

  const { instance } = useMsal();
  const [ssoAccessToken, setSSOAccessToken] = useState('');

  const handleSSOLogin = () => {
    instance.loginPopup(loginReq).then((response) => {
      let email = response.account.username;
      setLoading(true);
      dispatch(getSalesforceAccessToken(email)); // Dispatch the thunk action
      setLoading(false);
    });
  };

  //--------------------------------------------------------------------------------------------------//

  return (
    <>
      <div className="container">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="login">
              <div className="login__title w-100">
                <h6 className="login__name text-center">LET'S GET STARTED</h6>
              </div>
              <div>
                <Loader loading={loading} />

                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ width: "100%" }}
                >
                  <div className="login__inputField">
                    <div className="row">
                      <div className="col-12" style={{ paddingRight: "0" }}>
                        <input
                          type="text"
                          placeholder="Email ID"
                          {...register("email", { required: true })}
                        />
                      </div>
                    </div>
                    {errors.email && <p>Please enter the Email ID</p>}
                    <div className="row">
                      <div className="d-flex" style={{ paddingRight: "0" }}>
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          {...register("password", { required: true })}
                        />
                      </div>
                    </div>
                    {passwordShown ? (
                      <BsEyeSlash
                        onClick={togglePassword}
                        className="login_eye"
                      />
                    ) : (
                      <BsEye onClick={togglePassword} className="login_eye" />
                    )}
                    {errors.password && (
                      <p style={{ position: "relative", bottom: "2rem" }}>
                        Please enter the Password
                      </p>
                    )}
                  </div>
                  <div className="login__checkBoxForgot">
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        label="Remember Me"
                        className="login__checkBox"
                      />
                    </Form.Group>
                    <Link
                      className="login__forgotPassword"
                      onClick={() => setShow(true)}
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="login__submit">
                    <Button
                      variant="primary"
                      type="submit"
                      className="login__submitBtn"
                    >
                      Login
                      <BiLogInCircle
                        style={{
                          marginLeft: "4px",
                          height: "1.1em",
                          width: "1.1em",
                        }}
                      />
                    </Button>
                  </div>
                  <ModalPopup
                    className="login__forgotPasswordBox"
                    isModalOpen={show}
                    setIsModalOpen={handleClose}
                    backdrop={true}
                    fade={false}
                    data={
                      <>
                        <div className="login__forgotModal">
                          <h6 className="login__resetHeading">
                            Reset Password
                          </h6>
                          <span>Please enter the valid Pidilite Email Id</span>
                          <Form>
                            <form>
                              <input
                                type="email"
                                name="emailId"
                                value={emailId}
                                placeholder="Enter Email Id"
                                className="login__inputBox"
                                onChange={(e) => {
                                  setEmailId(e.target.value);
                                  setFormValidation({});
                                }}
                              />
                            </form>
                            {(!emailId || !emailId?.match(mailformat)) &&
                              formValidation?.email && (
                                <p className="errorMsg">
                                  {formValidation?.email}
                                </p>
                              )}
                            <div className="login__modalBtn">
                              <Button
                                className="login__cancelBTn"
                                onClick={handleClose}
                              >
                                Cancel
                              </Button>
                              <Button
                                className="login__resetBTn"
                                onClick={onReset}
                              >
                                Reset
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </>
                    }
                  />
                </Form>
                <div className="login__ssoBox">
                  <Button
                    variant="primary"
                    type="submit"
                    className="login__ssoSubmitBtn"
                    onClick={handleSSOLogin}
                  >
                    Login with Outlook
                    <FaWindows 
                      style={{
                        marginLeft: "4px",
                        height: "1.1em",
                        width: "1.1em",
                      }}
                    />
                  </Button>
                </div>
                <div className="login__appVersion">M-Connect v{appVersion}</div>
              </div>
              {isResetPassword && (
                <ModalPopup
                  className="ModalPopup_Submitted"
                  isModalOpen={isResetPassword}
                  setIsModalOpen={setIsResetPassword}
                  title="Reset Password"
                  data={
                    <>
                      <p style={{ marginTop: "0.5rem" }}>
                        Password Reset link has been successfully sent on your
                        email address <b>{emailId}</b>
                      </p>
                    </>
                  }
                  icon={
                    <>
                      <div className="passResetIcons">
                        <BsCheckCircle className="resetSmall" />
                        <BsCheckCircle className="resetLarge" />
                        <BsCheckCircle className="resetSmall" />
                      </div>
                    </>
                  }
                />
              )}
            </div>
          </div>
        </div>
        {error && (
          <CommonSnackbar
            open={failedOpen}
            autoHideDuration={6000}
            onClose={handleOnClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            severity={"error"}
            message={`${error}`}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state?.tokens?.isAuthenticated,
  globalAccessToken: state?.tokens?.globalAccessToken,
  accessToken: state?.tokens?.accessToken,
});

export default connect(mapStateToProps, {
  getUserAccessToken,
  getGlobalAccessToken,
})(Login);
