import React, { useState, useEffect } from "react";
import "./DRMPlanOne.css"
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MyDatePicker from "../Common/DatePicker";
import ModalPopup from "../Common/ModalPopup";
import CommonSnackbar from "../Common/CommonSnackbar";
import { format } from 'date-fns';
import { FaThumbsUp } from "react-icons/fa";
import styles from "../MarketVisitReport/MarketVisitReport.module.css";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from "@mui/material";
import { FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { DRMPlanOneAPI } from "../../services/DRM/DRMPlanOneAPI";
import { UpdateMeeting } from "../../services/DRM/UpdateMeeting";
import { getMeetingDetails } from "../../services/DRM/getMeetingDetails";
import Loader from "../Common/Loader";
import { getUniqueListBy } from "../Common/Function";
import { Quarter, ObjectiveOfMeet, ObjectiveOfMeet301, TypeOfDRM, TypeOfDRM301, TownSelectionCriteria, TownSelectionCriteria301, DealerSelectionCriteria, DealerSelectionCriteria301, IfCategorySpecificDealersThenWhichCategory, IfCategorySpecificDealersThenWhichCategory301 } from "../Common/Constant";
import _ from "lodash";
import { getWssApi } from "../../services/MarketWorking/MyTeam/getWssApi";
import { SPECASubmit } from "../../services/DRM/SPECASubmit";
import { MdOutlineAttachment } from "react-icons/md";
import { getTerritory } from "../../services/MarketWorking/MyTeam/getTerritory";
import { getDealerCityDRMApi } from "../../services/DRM/getDealerCityDRMApi";
import { getEmployeeIdApi } from "../../services/DRM/getEmployeeIdApi";

const territoryAutoCompletePlaceHolder = {
  attributes: null,
  Id: null,
  SH2_Name__c: null,
  SH2_Code__c: null,
  Name: null,
  SH3_Code__c: null,
};
const wsscodeAutoCompletePlaceHolder = {
  attributes: null,
  CustomerName__c: null,
  Customer_Code__c: null,
  Id: null,
  Monthly_Tour_Plan_CheckIn__c: null,
  TSI_Territory_Code__c: null,
  Town__c: null,
  WSS_latitude__c: null,
  WSS_longitude__c: null,
};
const townAutocompletePlaceHolder = {
  attributes: null,
  Id: null,
  TownCode__c: null,
  Town__c: null
}

const dealerAutocompletePlaceHolder = {
  attributes: null,
  Id: null,
  CityCode_c: null,
  DealerCity__c: null
}

const options = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5x"];

const DRMPlanOne = ({ formData, failedOpen, setFailesOpen, snackerror, setSnackError, setTownName, showReason, DisableSubmitDRM, setDisableSubmitDRM, setShowReason, feedbackcount, statusCompleted, disableTown, townlist, setDisableUpdate, disableUpdate, setSpecaObj, specaObj, startExecution, setStartExecution, setUnableUpdate, setMeetingDetails, setDisableActualDate, setTabValue, QRvisible, setExecutionDisable, setDisable, territoryOption, setLoading, disableactualdate, initialFormData, mindate, setFormData, setBlockTab, meetingdetails, executiondisable, disable, unableupdate, setErrors, errors, setTriggerMeetings, loading }) => {
  const userEmailid = useSelector((state) => state?.userDetails?.details?.Email);
  const clusterCode = useSelector((state) => state?.userDetails?.details?.SH_Code__c);
  const zone = useSelector((state) => state?.userDetails?.details?.Division);
  const userType = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);
  const salesGroupCode = useSelector((state) => state?.userDetails?.details?.Sales_Group_Code__c);
  const [territoryPickList, setTerritoryPickList] = useState();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [specamodal, setSpecaModal] = useState(false);
  const [wsspicklist, setWssPicklist] = useState();
  const [townPicklist, setTownPicklist] = useState();
  const [testPicklist, setTestPicklist] = useState("TANZEEM : 1045750,PARAS REXINE HOUSE : 1047358,PEARLS WALL PAPER CO : 1023558")
  const tname = useSelector((state) => state?.territory?.territoryDetails);
  const [wssOption, setWssOption] = useState([]);
  const [townNameOption, setTownNameOption] = useState([]);
  const [territoryDropOption, setTerritoryDropOption] = useState([]);
  const [wsscode, setWssCode] = useState([]);
  const [error, setError] = useState("");
  const [isWssData, setIsWssData] = useState("Loading");
  const [isTerritoryData, setIsTerritoryData] = useState("Loading");
  // const [failedOpen, setFailesOpen] = useState(false);
  const [selectedWss, setSelectedWss] = useState({});
  // const [snackerror, setSnackError] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [fileValidation, setFileValidation] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [file, setFile] = useState([]);
  const [selectedTown, setSelectedTown] = useState(townAutocompletePlaceHolder)

  const [selectedTerritory, setSelectedTerritory] = useState(
    territoryAutoCompletePlaceHolder
  );
  const [value, setValue] = useState(null);

  // dealer City
  const [dealerCityOptions, setDealerCityOptions] = useState();
  const [isDealerCityData, setIsDealerCityData] = useState("Loading");
  const [selectedDealerCity, setSelectedDealerCity] = useState(dealerAutocompletePlaceHolder)
  const [employeeId, setEmployeeId] = useState("");
  useEffect(() => {
    const event = {
      name: "salesGroupUserType",
      value: selectedOptions.map((item) => `${item.CustomerName__c} : ${item?.Customer_Code__c}`).join(","),
    };

  }, [selectedOptions, formData, file]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getEmployeeIdApi(userEmailid);
        console.log("Employee ID res", result)
        if (!_.isEmpty(result?.data?.records[0])) {
          setEmployeeId(result?.data?.records[0].Employee_Code__c);
        }
      } catch (error) {
        setEmployeeId('');
        setLoading(false);
      }
    })();
  }, [userEmailid])

  console.log("Employee id", employeeId)

  const handleFileChange = async (event) => {
    setFileValidation("");
    setFile([]);
    const files = event.target.files;
    const filesArray = Array.from(files);

    let invalidFiles = [];
    filesArray.forEach((file) => {
      if (file.size > 10 * 1024 * 1024) {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length > 0) {
      setFileValidation(`Files exceed 10 MB: ${invalidFiles.join(", ")}`);
    }
    else if (filesArray?.length > 5) {
      setFileValidation("Please select Maximum 5 files");
    } else {
      setSelectedFiles(filesArray);
      filesArray?.map(async (file, index) => {
        const fileExtension = file?.name?.split(".")[1]
        const base64 = await convertBase64(file);
        setFile((fs) => [
          ...fs,
          {
            ImageName: `${meetingdetails?.Id}_${index + 1}.${fileExtension}`,
            ImageContent: base64.replace("data:", "").replace(/^.+,/, ""),

          },
        ]);
      });
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    const uniq = getUniqueListBy(
      territoryOption,
      "SH2_Code__c"
    )
    setFormData((prevState) => ({
      ...prevState,
      SG: meetingdetails?.SG__c,
      Zone: meetingdetails?.Zone__c,
      ClusterName: meetingdetails?.Cluster_Name__c,
      // CustomerCode: meetingdetails?.Customer_Code__c,
      TSITerritoryName: meetingdetails?.TSI_Territory_Name__c,
      TownName: meetingdetails?.Town_name__c,
      // TownCode: meetingdetails?.Town_Code__c,
      DealerCity: meetingdetails?.Dealer_City__c,
      CustomerName: meetingdetails?.Customer_Name__c,
      Population: meetingdetails?.Population__c,
      // Quarter: meetingdetails?.Quarter__c,
      ObjectiveOfMeet: meetingdetails?.Objective_of_meet__c,
      TypeOfDRM: meetingdetails?.Type_of_DRM__c,
      TownSelectionCriteria: meetingdetails?.Town_Selection_criteria__c,
      DealerSelectionCriteria: meetingdetails?.Dealer_Selection_Criteria__c,
      IfCategorySpecificDealersThenWhichCategory: meetingdetails?.If_Category_Specific_dealers_then_which__c,
      NoOfPCCdealersInTown: meetingdetails?.No_of_PCC_dealers_in_town__c,
      EstimatedNoOfDealersToParticipate: meetingdetails?.Estimated_no_of_Dealers_to_Participate__c,
      TotalHeadCountDealer: meetingdetails?.Total_Head_Count_Dealer__c,
      TotalHeadCountEmployee: meetingdetails?.Total_Head_Count_Employee__c,
      TotalCost: meetingdetails?.Total_Cost__c,
      CurrentMonthlyAverageSalesOfTown: meetingdetails?.Current_monthly_average_sales_of_Town__c,
      PostDRMMonthlyAverageSalePlannedForTheTown: meetingdetails?.Post_DRM_monthly_average_sale_planned__c,
      CostPerHead: meetingdetails?.Cost_Per_Head__c,
      ReasonForCost: meetingdetails?.Reason_for_Cost__c,
      TentativeDateOfTheMeet: meetingdetails?.Tentative_date_of_the_meet__c ? dayjs(meetingdetails.Tentative_date_of_the_meet__c) : null,
      ActualDateOfTheMeet: meetingdetails?.Actual_Date_of_the_Meet__c ? dayjs(meetingdetails.Actual_Date_of_the_Meet__c) : null
    }));



  }, [meetingdetails])
  //------------------------------ Check SG for prefix -------------------------------//

  const salesGroupMap = {
    "105": "FV",
    "301": "MNT",
    "401": "CC",
    "406": "ROFF",
    "901": "EI",
    "902": "EI"
  };

  const generateSharedId = (salesGroupCode, id) => {
    const prefix = salesGroupMap[salesGroupCode] || "UNKNOWN"; // Default if not found
    return `${prefix}${id}`;
  };

  //-----------------------------------------------------------------------------------//

  useEffect(() => {
    setSpecaObj((prevState) => ({
      ...prevState,
      "Division": zone,
      "SalesGroup": formData?.SG,
      "HierarchyCode": formData?.TSITerritoryName.split(":").pop().trim(),
      "VerticalName": formData?.SG,
      "MeetingDate": formData?.ActualDateOfTheMeet,
      "MeetingType": "DRM (MANAGED BY FIELD)",
      "MeetingCode": generateSharedId(formData?.SG, meetingdetails?.Id),
      "CityName": formData?.TownName.split(":")[0].trim(),
      "TownCode": "UGJ00006", // 8 characters
      "PerHeadCost": formData?.CostPerHead,
      "InviteeCount": Number(`${formData?.TotalHeadCountDealer}`) + Number(`${formData?.TotalHeadCountEmployee}`), /// validation 0..00
      "AttendeeCount": Number(`${formData?.ActualNoOfDealersParticipated}`) + Number(`${formData?.ActualNoOfEmployeesParticipated}`), /// validation 0..00
      "TotalAmount": formData?.ActualCost,
      "AttendeesPhoto": "()",
      "Billno": "0",
      "ITrakToTEMFlag": "Y",
      "ITrakToTEMDate": "2024-01-02 00:00:00",
      "Attendee_KYCRecieved": 0,
      "Attendee_KYCNotRecieved": 0,
      "Attendee_Stakeholders": 0,
      "Attendee_Helper": 0,
      "Attendee_Repeat": "0",
      "MeetingFormat": "Physical Meet",
      "ADV_AND_PROMO": "",
      "EMPLOYEECODE": employeeId
    }))
  }, [formData])
  //// snackbar close //////////
  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
  };

  //// Set Territory and WSS for current meeting
  useEffect(() => {
    const territoryid = tname.filter((territory) => territory?.SH2_Name__c === meetingdetails?.TSI_Territory_Name__c)

    // setTerritoryPickList(territoryid[0] ? `${territoryid[0].SH2_Name__c} : ${territoryid[0].SH2_Code__c}` : "")
    setTerritoryPickList(meetingdetails?.TSI_Territory_Name__c ? meetingdetails?.TSI_Territory_Name__c : "")

    // setWssPicklist(meetingdetails?.Customer_Code__c ? `${meetingdetails?.Customer_Name__c} : ${meetingdetails?.Customer_Code__c}` : "") 
    setWssPicklist(meetingdetails?.Customer_Name__c ? meetingdetails?.Customer_Name__c : "")
    setTownPicklist(meetingdetails?.Town_name__c ? meetingdetails?.Town_name__c : "")
  }, [meetingdetails])


  // Calculate CostPerHead whenever TotalCost or TotalHeadCount changes
  // useEffect(() => {

  //   if (formData.TotalCost && formData.TotalHeadCount) {
  //     const costPerHead = (formData.TotalCost / formData.TotalHeadCount).toFixed(2);
  //     setFormData({ ...formData, CostPerHead: costPerHead });
  //   } else {
  //     setFormData({ ...formData, CostPerHead: "" });
  //   }
  // }, [formData.TotalCost, formData.TotalHeadCount]);

  // Calculate TotalCost whenever CostPerHead or TotalHeadCount changes
  useEffect(() => {

    if (formData.CostPerHead && formData.TotalHeadCountDealer && formData.TotalHeadCountEmployee) {
      const TotalCost = (Number(formData.TotalHeadCountDealer) + Number(formData.TotalHeadCountEmployee)) * Number(formData.CostPerHead);
      setFormData({ ...formData, TotalCost: TotalCost });
    } else {
      setFormData({ ...formData, TotalCost: "" });
    }
  }, [formData.CostPerHead, formData.TotalHeadCountDealer, formData.TotalHeadCountEmployee]);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if ((name == 'ActualNoOfDealersParticipated' || name == 'ActualNoOfEmployeesParticipated' || name == 'ActualCost') && value !== '') {
      let num = Number(value);
      setFormData({ ...formData, [name]: num });
    }
    else if (name == 'Population' && value == '') {
      setFormData({ ...formData, [name]: null });
    }
    else {

      setFormData({ ...formData, [name]: value });
    }

    if (name === 'Population' && value > 50) {
      setErrors({ ...errors, Population: 'Total Population can not be greater than 50 lacs' });
    }

    // else if (name === 'TotalHeadCount' && value > 70) {
    //   setErrors({ ...errors, [name]: 'Total head count should be 70 or below' });
    // } 
    else if ((name === 'ActualNoOfDealersParticipated' || name === 'NoOfPCCdealersInTown'
      || name === 'EstimatedNoOfDealersToParticipate' || name === 'TotalHeadCountDealer'
      || name === 'TotalHeadCountEmployee' || name === 'CostPerHead'
      || name === 'CurrentMonthlyAverageSalesOfTown' || name === 'PostDRMMonthlyAverageSalePlannedForTheTown')
      && !Number.isInteger(Number(value))) {
      setErrors({ ...errors, [name]: 'Can not enter decimal value' });
    }
    else {
      setErrors({ ...errors, [name]: '' });
    }

    if (name === 'CostPerHead') {
      setShowReason(value > 1000);
    }
  };

  const handleAutocompleteChange = (name, newValue) => {

    if (name == "TSITerritoryName") {
      setFormData({ ...formData, [name]: `${newValue?.TSI_Territory_Name__c} : ${newValue?.TSI_Territory_Code__c}`, "CustomerName": "" });
      setSelectedWss({});
      setSelectedOptions([])
      setWssPicklist(false)
    }
    else if (name == 'TownName') {
      setFormData({ ...formData, [name]: `${newValue?.Town__c} : ${newValue?.TownCode__c}`, TSITerritoryName: "" });
      setTerritoryPickList("")
      setSelectedTerritory("")
    }
    else if (name == "CustomerName") {

      setFormData({
        ...formData,
        [name]: newValue.map((item) => `${item.CustomerName__c} : ${item?.Customer_Code__c}`).join(",")
      });
      // "TownName": newValue.Town__c 
      // setFormData({ ...formData, [name]: newValue.Customer_Code__c,"CustomerName" : newValue.CustomerName__c,"TownName": newValue.Town__c });
    } else {
      setFormData({ ...formData, [name]: newValue })
    }

  };

  const handleDateChange = (name, newValue) => {

    // const date = new Date(newValue);
    // const tentativeDateOfMeet = format(date, 'yyyy-MM-dd');
    setFormData({
      ...formData,
      [name]: newValue,
      ActualDateOfTheMeet: newValue, // Keep ActualDateOfTheMeet in sync with TentativeDateOfTheMeet
    });
  };

  const validateExecution = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if (!formData[key] && formData[key] !== 0) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;

  }

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach(key => {
      if ((key !== 'ActualNoOfDealersParticipated' && key !== 'ActualNoOfEmployeesParticipated' && key !== 'BMRemark' && key !== 'ActualCost' && key !== 'ReasonForCost' && key !== 'Population') || (startExecution && (key === 'ActualNoOfDealersParticipated' || key === 'BMRemark' || key === 'ActualCost' || key === 'ActualNoOfEmployeesParticipated')) || (showReason && (key === 'ReasonForCost'))) {
        if (!formData[key] && formData[key] !== 0) {
          newErrors[key] = "This field is required";
        }

      }


    });

    // if (formData.TotalHeadCount > 70) {
    //   newErrors.TotalHeadCount = 'Total head count should be 70 or below';
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleUpdate = async (e) => {
    // format actual date ////
    const actualDate = formData?.ActualDateOfTheMeet;
    const currentmeetingid = meetingdetails?.Id;
    const formattedDate = actualDate ? format(new Date(actualDate), 'yyyy-MM-dd') : undefined;

    if (validateForm()) {

      try {
        const formDataWithId = {
          ...formData,
          id: meetingdetails?.Id,
          NSMStatus: meetingdetails?.NSM_Status__c,
          ZSMStatus: meetingdetails?.ZSM_Status__c,
          ActualDateOfTheMeet: formattedDate,
          TentativeDateOfTheMeet: formattedDate,
          NSMFeedback: meetingdetails?.NSM_Feedback__c,
        };
        setLoading(true)
        const response = await UpdateMeeting({ formDataWithId })

        if (response?.success) {
          setDisableUpdate(true)
          const response = await getMeetingDetails({ currentmeetingid })
          if (response?.data?.records[0]?.NSM_Status__c == "Approved") {
            setMeetingDetails(response?.data?.records[0])
            setDisableUpdate(false)
          }
          setIsUpdateModalOpen(true)
          setLoading(false)

          // setCurrentMeetingid("a0yIn000000L2XcIAK")
          setDisable(true)
        }
        else {
          setFailesOpen(true)
          setSnackError(response?.msg);
          setLoading(false)
        }
      }
      catch (error) {
        console.error("Error during API call:", error);
        setSnackError("Something went wrong..")
        setFailesOpen(true)
      }



    }
  }

  ///// creation of new Meeting //////////////
  const handleSubmit = async (e) => {
    e.preventDefault();
    const actualDate = formData?.TentativeDateOfTheMeet;
    const formattedDate = actualDate ? format(new Date(actualDate), 'yyyy-MM-dd') : undefined;
    const newFormData = {
      ...formData,
      TentativeDateOfTheMeet: formattedDate,
      ActualDateOfTheMeet: formattedDate
    };

    if (validateForm()) {
      try {
        setLoading(true)
        const response = await DRMPlanOneAPI({ newFormData });
        if (response?.success) {
          setTriggerMeetings(true);
          setFormData(initialFormData);
          setIsSubmitModalOpen(true)
          setLoading(false)
          setSelectedWss({});
        }
        else {
          setSnackError(response?.msg);
          setFailesOpen(true)
          setLoading(false)
        }
      }
      catch (error) {
        console.error("Error during API call:", error);
        setSnackError("Something went wrong..")
        setFailesOpen(true)
      }


    }
  }
  ////// Validate Attched Image ///
  const validateFile = () => {
    if (!file || file.length === 0) {
      setFileValidation("Please Attach Your Photo");
      return false; // Indicating validation failed
    } else {
      return true; // Indicating validation passed
    }
  }
  ////// handle final submission of DRM Form //////////////
  const handleSpecaSubmit = async (e) => {
    //// format current time yyyy-MM-dd HH:mm:ss in this format ////
    const convertDateToSpecaFormat = (originalDate) => {
      const utcYear = originalDate.getUTCFullYear();
      const utcMonth = originalDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
      const utcDay = originalDate.getUTCDate();
      const utcHours = originalDate.getUTCHours();
      const utcMinutes = originalDate.getUTCMinutes();

      // Format the date into "YYYY-MM-DD HH:mm:ss.SSSZ"
      const formattedDate = `${utcYear}-${pad(utcMonth)}-${pad(utcDay)} ${pad(utcHours)}:${pad(utcMinutes)}:00.000Z`;



      // Function to pad single digit numbers with leading zeros
      function pad(number) {
        if (number < 10) {
          return '0' + number;
        }
        return number;
      }
      return formattedDate
    }
    /// convert current local time to utc ///
    const now = new Date();
    const CurrentDate = convertDateToSpecaFormat(now);
    /// convert Actual date of meet to utc ///
    const date = new Date(formData?.ActualDateOfTheMeet);
    const MeetingDate = convertDateToSpecaFormat(date);


    //// trim the town code from townname string ///
    const originalString = formData?.TownName
    const townCode = originalString.split(':')[1].trim();



    ///// update current speca object with formated values /// 
    const NextSpecaObj = {
      ...specaObj,
      "MeetingDate": MeetingDate,
      "ITrakToTEMDate": CurrentDate,
      "TownCode": townCode
    };


    if (validateForm() && validateFile()) {
      try {
        setLoading(true)
        const response = await SPECASubmit({ NextSpecaObj })
        if (response?.success == true) {
          const actualDate = formData?.TentativeDateOfTheMeet;
          const formattedDate = actualDate ? format(new Date(actualDate), 'yyyy-MM-dd') : undefined;

          const formDataWithId = {
            ...formData,
            id: meetingdetails?.Id,
            TentativeDateOfTheMeet: formattedDate,
            ActualDateOfTheMeet: formattedDate,
            NSMStatus: "Completed",
            ZSMStatus: "Completed",
            Images: file

          }
          const updateresponse = await UpdateMeeting({ formDataWithId })
          if (updateresponse?.success == true) {
            setDisableSubmitDRM(true)
            setLoading(false)
            setSpecaModal(true)
          }
          else {
            setFailesOpen(true)
            setSnackError("something went wrong.. try again after some time or contact your admin for further assistance..")
            setLoading(false)

          }
        }
        else {
          // setSnackError(response?.msg)
          setFailesOpen(true)
          setSnackError("something went wrong.. try again after some time or contact your admin for further assistance..")
          setLoading(false)
        }


      } catch (error) {
        console.error("Error during API call:", error);
        setFailesOpen(true)
        setSnackError("something went wrong..")
        setLoading(false)
      }




    }

  }
  ///// On click QR Code start meeting ///
  const startMeeting = () => {
    const today = new Date();
    const todayDate = today.toISOString().split('T')[0];
    const meetingDate = meetingdetails?.Actual_Date_of_the_Meet__c;
    if (meetingDate === todayDate) {
      setBlockTab(true);
      setExecutionDisable(true)
      setUnableUpdate(true)
      setStartExecution(true)
      setTabValue("2")
      setDisableActualDate(true)

    } else {
      setFailesOpen(true)
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
      setSnackError("Please update you actual date of meet to current date");
    }



  }
  useEffect(() => {
    setIsTerritoryData("Loading");
    {
      (async () => {
        let TownCode__c = selectedTown?.TownCode__c;
        const response = await getTerritory({ TownCode__c, clusterCode });

        if (response?.success) {
          if (response?.data?.totalSize === 0) {
            setIsTerritoryData("false");
          } else {
            setIsTerritoryData("true");
            // setWssOption(response?.data?.records);
            // setTownNameOption(response?.data?.records)
            setTerritoryDropOption(response?.data?.records)

            // setSelectedTerritory("");
          }
        } else {
          setSnackError(response?.msg);
          setFailesOpen(true)
        }
      })();
    }


  }, [selectedTown])

  // Wss Name API call
  useEffect(() => {
    setIsWssData("Loading")
    {
      (async () => {
        let SH2_Code__c = selectedTerritory?.TSI_Territory_Code__c;
        const response = await getWssApi({ SH2_Code__c });
        if (response?.success) {
          if (response?.data?.totalSize === 0) {
            setIsWssData("false")
          } else {
            setIsWssData("true")
            setWssOption(response?.data?.records);
            setTownNameOption(response?.data?.records)


            // setSelectedTerritory("");
          }
        } else {
          setSnackError(response?.msg);
          setFailesOpen(true)
        }
      })();
    }
  }, [selectedTerritory]);

  // ---------------------------------------- Dealer City -------------------------------------------------//

  useEffect(() => {
    if (clusterCode) {
      (async () => {
        setIsDealerCityData("Loading")
        const res = await getDealerCityDRMApi(clusterCode);
        if (res?.success) {
          if (res?.data?.length === 0) {
            setIsDealerCityData("false")
            setDealerCityOptions(res?.data);
          } else {
            setDealerCityOptions(res?.data);
            setIsDealerCityData("true")
          }
        }
      })();
    }
  }, [])

  // --------------------------------------------------------------------------------------------------------- //

  return (<div className="p-0">
    <CommonSnackbar
      open={failedOpen}
      autoHideDuration={7000}
      onClose={handleOnClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      severity={"error"}
      message={snackerror}
    />
    <Loader loading={loading} />
    <div className="drm__Innerbox">
      {statusCompleted && (<><div className="DRM_QR"><h6 style={{ color: "green" }}>Dealer Feedback count : {feedbackcount}</h6></div></>)}
      {QRvisible && (<div className="DRM_QR" onClick={() => startMeeting()}><button className="start_meet"><p className="start_meeting_text">Activate QR</p> </button></div>)}
      <form onSubmit={handleSubmit} >
        {/* <div className="input_field_outer">
                   <label className= "DRM_Form_label">
                     SG
                   </label>
                   <input
                     className= "DRM_Form_text_input"
                     style={{ paddingLeft: "8px" }}
                     name="SG"
                     value= {formData.SG}
                     onChange= {handleInputChange}
                     disabled= {disable}
                     maxLength={256}
                   />
                   {errors.SG && <p className={styles.MarketVisitReport__validationErrors}>{errors.SG}</p>}
                </div>
                <div  className="input_field_outer">
                   <label className= "DRM_Form_label">
                     zone
                   </label>
                   <input
                     className= "DRM_Form_text_input"
                     style={{ paddingLeft: "8px" }}
                     name="Zone"
                     value= {formData.Zone}
                     onChange= {handleInputChange}
                     disabled= {disable}
                     maxLength={256}
                   />
                   {errors.Zone && <p className={styles.MarketVisitReport__validationErrors}>{errors.Zone}</p>}
                </div> */}
        {/* <div className="input_field_outer">
          <label className="DRM_Form_label">
            Cluster Name
            
          </label>
          <input
            className="DRM_Form_text_input"
            style={{ paddingLeft: "8px" }}
            name="ClusterName"
            value={formData.ClusterName}
            onChange={handleInputChange}
            disabled={true}
            maxLength={256}
          />
          {errors.ClusterName && <p className={styles.MarketVisitReport__validationErrors}>{errors.ClusterName}</p>}
        </div> */}


        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Town Name

          </label>
          <Autocomplete

            id="grouped-demo"
            className="drm__categorySelect"
            options={
              !_.isEmpty(townlist) ? getUniqueListBy(townlist, "TownCode__c") : []
            }
            getOptionLabel={(option) => {
              if (option?.Town__c) {
                return `${option?.Town__c} : ${option?.TownCode__c}`;
              } else {
                return "";
              }
            }}
            disabled={disable || disableTown}
            onChange={(event, newValue) => {
              setSelectedTown(newValue);
              handleAutocompleteChange("TownName", newValue)
            }}

            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={townPicklist ? townPicklist : "Select Town Name"}
              />
            )}
          />

          {errors.TownName && <p className={styles.MarketVisitReport__validationErrors}>{errors.TownName}</p>}
        </div>

        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Dealer City
          </label>
          <Autocomplete
            id="grouped-demo"
            disableClearable={true}
            className="drm__categorySelect"
            options={
              isDealerCityData === "Loading"
                ? [
                  {
                    DealerCity__c: "Loading..."
                  },
                ]
                : isDealerCityData === "false" ? [
                  {
                    DealerCity__c: "No Data"
                  },
                ] : !_.isEmpty(dealerCityOptions) ? dealerCityOptions : []
            }
            getOptionLabel={(option) => {
              if (option?.DealerCity__c) {
                return `${option?.DealerCity__c} : ${option.CityCode__c}`;
              } else {
                return "";
              }
            }}
            onChange={(e, newValue) => {
              setSelectedDealerCity(newValue);
              handleAutocompleteChange("DealerCity", `${newValue?.DealerCity__c} : ${newValue.CityCode__c}`)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={formData?.DealerCity ? formData?.DealerCity : "Select Dealer City"}
              />
            )}
          />

          {errors.DealerCity && <p className={styles.MarketVisitReport__validationErrors}>{errors.DealerCity}</p>}
        </div>

        <div className="input_field_outer">
          <label className="DRM_Form_label">
            TSI Territory Name
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>

          <Autocomplete
            id="grouped-demo"
            className="drm__categorySelect"
            isTerritoryData
            options={
              isTerritoryData === "Loading"
                ? [
                  {
                    TSI_Territory_Name__c: "Loading...",
                    TSI_Territory_Code__c: "",
                  },
                ]
                : isTerritoryData === "false" ? [
                  {
                    TSI_Territory_Name__c: "No Data",
                    TSI_Territory_Code__c: "",
                  },
                ] : getUniqueListBy(territoryDropOption, "TSI_Territory_Code__c")
            }
            getOptionLabel={(option) => {
              if (option?.TSI_Territory_Name__c) {
                return `${option?.TSI_Territory_Name__c} ${option?.TSI_Territory_Code__c && ":"
                  } ${option?.TSI_Territory_Code__c}`;
              } else {
                return "";
              }
            }}
            value={selectedTerritory}
            // value={territoryPickList}

            disabled={disable || disableTown}
            // name="dealerId"
            onChange={(event, newValue) => {
              setSelectedTerritory(newValue);
              handleAutocompleteChange("TSITerritoryName", newValue || "")
              setWssOption([]);

            }}
            renderInput={(params) => (
              <TextField {...params} placeholder={territoryPickList ? territoryPickList : "select territory"} />
            )}
          />



          {errors.TSITerritoryName && <p className={styles.MarketVisitReport__validationErrors}>{errors.TSITerritoryName}</p>}
        </div>
        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Customer Code(WSS Code)
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>

          <Autocomplete
            multiple
            id="grouped-demo"
            className="drm__categorySelect"
            options={
              isWssData === "Loading"
                ? [
                  {
                    CustomerName__c: "Loading...",
                    Customer_Code__c: "",
                  },
                ]
                : isWssData === "false" ? [
                  {
                    CustomerName__c: "No Data",
                    Customer_Code__c: "",
                  },
                ] : getUniqueListBy(wssOption, "CustomerName__c")
            }
            getOptionLabel={(option) => {
              if (option?.CustomerName__c) {
                return `${option?.CustomerName__c} ${option?.Customer_Code__c && ":"
                  } ${option?.Customer_Code__c}`;
              } else {
                return "";
              }
            }}
            value={selectedOptions}
            disabled={disable || disableTown}
            onChange={(event, newValue) => {
              setSelectedOptions(newValue);
              handleAutocompleteChange("CustomerName", newValue)
            }}

            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={wsspicklist ? wsspicklist : "Select Wss Name"}
              />
            )}
          />



          {errors.CustomerName && <p className={styles.MarketVisitReport__validationErrors}>{errors.CustomerName}</p>}
        </div>
        {/* <div className="input_field_outer">
          <label className="DRM_Form_label">
            Customer Code(WSS Code) 
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <Autocomplete
            multiple
            id="grouped-demo2"
            className="drm__categorySelect"
            options={
              isWssData === "Loading"
                ? [
                  {
                    CustomerName__c: "Loading...",
                    Customer_Code__c: "",
                  },
                ]
                : isWssData === "false" ? [
                  {
                    CustomerName__c: "No Data",
                    Customer_Code__c: "",
                  },
                ] : wssOption
            }
            getOptionLabel={(option) => {
              if (option?.CustomerName__c) {
                return `${option?.CustomerName__c} ${option?.Customer_Code__c && ":"
                  } ${option?.Customer_Code__c}`;
              } else {
                return "";
              }
            }}
            name="subCategory"
          
            disabled={disable}
            onChange={(event, newValue) => {
              setSelectedOptions(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder= {wsspicklist ? wsspicklist : "Select Wss Name"} />
            )}
          />
          {errors.CustomerCode && <p className={styles.MarketVisitReport__validationErrors}>{errors.CustomerCode}</p>}
        </div> */}

        {/* <div className="input_field_outer">
          <label className="DRM_Form_label">
            Customer Code(WSS Code) 
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <Autocomplete
           
            id="grouped-demo2"
            className="drm__categorySelect"
            options={
              isWssData === "Loading"
                ? [
                  {
                    CustomerName__c: "Loading...",
                    Customer_Code__c: "",
                  },
                ]
                : isWssData === "false" ? [
                  {
                    CustomerName__c: "No Data",
                    Customer_Code__c: "",
                  },
                ] : wssOption
            }
            getOptionLabel={(option) => {
              if (option?.CustomerName__c) {
                return `${option?.CustomerName__c} ${option?.Customer_Code__c && ":"
                  } ${option?.Customer_Code__c}`;
              } else {
                return "";
              }
            }}
            name="subCategory"
            value={selectedWss}
            disabled={disable}
            onChange={(event, newValue) => {
              // setSelectLatLong(newValue);
              setSelectedWss(newValue)
              handleAutocompleteChange("CustomerCode",newValue)
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder= {wsspicklist ? wsspicklist : "Select Wss Name"} />
            )}
          />
          {errors.CustomerCode && <p className={styles.MarketVisitReport__validationErrors}>{errors.CustomerCode}</p>}
        </div> */}


        {/* <div className="input_field_outer">
          <label className="DRM_Form_label">
            Town Code
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            style={{ paddingLeft: "8px" }}
            name="TownCode"
            value={formData.TownCode}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.TownCode && <p className={styles.MarketVisitReport__validationErrors}>{errors.TownCode}</p>}
        </div> */}
        {/* <div className="input_field_outer">
          <label className="DRM_Form_label">
            Customer Name
          </label>
          <input
            className="DRM_Form_text_input"
            style={{ paddingLeft: "8px" }}
            name="CustomerName"
            value={formData.CustomerName}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.CustomerName && <p className={styles.MarketVisitReport__validationErrors}>{errors.CustomerName}</p>}
        </div> */}
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Population (in lacs)

          </label>
          <input
            className="DRM_Form_text_input"
            style={{ paddingLeft: "8px" }}
            type="number"
            name="Population"
            value={formData.Population}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.Population && <p className={styles.MarketVisitReport__validationErrors}>{errors.Population}</p>}
        </div>
        {/* <div  className="input_field_outer">
                   <label className= "DRM_Form_label">
                     Type of Competition Market
                   </label>
                   <div className="DRM_Multiselect_Wrapper">
                     <Autocomplete
                     className="drm__categorySelect"
                     options={options}
                     disabled= {disable}
                     style={{ width: '100%' }}
                     renderInput={(params) => (
                        <TextField {...params} placeholder={""} />
                      )}
                    value={formData.TypeOfCompetitionMarket}
                     onChange={(event, newValue) => handleAutocompleteChange("TypeOfCompetitionMarket",newValue)}
                     />
                   </div>
                   {errors.TypeOfCompetitionMarket && <p className={styles.MarketVisitReport__validationErrors}>{errors.TypeOfCompetitionMarket}</p>}
                </div> */}
        {/* <div className="input_field_outer">
          <label className="DRM_Form_label">
            Quarter (Q1/Q2/Q3/Q4)
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <div className="DRM_Multiselect_Wrapper">
            <Autocomplete
            
              className="drm__categorySelect"
              options={Quarter}
              disabled={disable}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} placeholder={""} />
              )}
              value={formData.Quarter}
              onChange={(event, newValue) => handleAutocompleteChange("Quarter", newValue)}
            />
          </div>
          {errors.Quarter && <p className={styles.MarketVisitReport__validationErrors}>{errors.Quarter}</p>}
        </div> */}
        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Objective of Meet
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <div className="DRM_Multiselect_Wrapper">
            <Autocomplete
              className="drm__categorySelect"
              options={(salesGroupCode === 301 || salesGroupCode === 302) ? ObjectiveOfMeet301 : ObjectiveOfMeet}
              disabled={disable}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} placeholder={""} />
              )}
              value={formData.ObjectiveOfMeet}
              onChange={(event, newValue) => handleAutocompleteChange("ObjectiveOfMeet", newValue)}
            />
          </div>
          {errors.ObjectiveOfMeet && <p className={styles.MarketVisitReport__validationErrors}>{errors.ObjectiveOfMeet}</p>}
        </div>
        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Type of DRM
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <div className="DRM_Multiselect_Wrapper">
            <Autocomplete
              className="drm__categorySelect"
              options={(salesGroupCode === 301 || salesGroupCode === 302) ? TypeOfDRM301 : TypeOfDRM}
              disabled={disable}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} placeholder={""} />
              )}
              value={formData.TypeOfDRM}
              onChange={(event, newValue) => handleAutocompleteChange("TypeOfDRM", newValue)}
            />
          </div>
          {errors.TypeOfDRM && <p className={styles.MarketVisitReport__validationErrors}>{errors.TypeOfDRM}</p>}
        </div>
        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Town Selection Criteria
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <div className="DRM_Multiselect_Wrapper">
            <Autocomplete
              className="drm__categorySelect"
              options={(salesGroupCode === 301 || salesGroupCode === 302) ? TownSelectionCriteria301 : TownSelectionCriteria}
              disabled={disable}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} placeholder={""} />
              )}
              value={formData.TownSelectionCriteria}
              onChange={(event, newValue) => handleAutocompleteChange("TownSelectionCriteria", newValue)}
            />
          </div>
          {errors.TownSelectionCriteria && <p className={styles.MarketVisitReport__validationErrors}>{errors.TownSelectionCriteria}</p>}
        </div>
        <div className="input_field_outer">
          <label className="DRM_Form_label">
            Dealer Selection Criteria
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <div className="DRM_Multiselect_Wrapper">
            <Autocomplete
              className="drm__categorySelect"
              options={(salesGroupCode === 301 || salesGroupCode === 302) ? DealerSelectionCriteria301 : DealerSelectionCriteria}
              disabled={disable}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} placeholder={""} />
              )}
              value={formData.DealerSelectionCriteria}
              onChange={(event, newValue) => handleAutocompleteChange("DealerSelectionCriteria", newValue)}
            />
          </div>
          {errors.DealerSelectionCriteria && <p className={styles.MarketVisitReport__validationErrors}>{errors.DealerSelectionCriteria}</p>}
        </div>
        <div className="input_field_outer">
          <label className="DRM_Form_label">
            If category specific dealers,then which category
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <div className="DRM_Multiselect_Wrapper">
            <Autocomplete
              className="drm__categorySelect"
              options={(salesGroupCode === 301 || salesGroupCode === 302) ? IfCategorySpecificDealersThenWhichCategory301 : IfCategorySpecificDealersThenWhichCategory}
              disabled={disable}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} placeholder={""} />
              )}
              value={formData.IfCategorySpecificDealersThenWhichCategory}
              onChange={(event, newValue) => handleAutocompleteChange("IfCategorySpecificDealersThenWhichCategory", newValue)}
            />
          </div>
          {errors.IfCategorySpecificDealersThenWhichCategory && <p className={styles.MarketVisitReport__validationErrors}>{errors.IfCategorySpecificDealersThenWhichCategory}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            No. of PCC dealers in town
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="NoOfPCCdealersInTown"
            value={formData.NoOfPCCdealersInTown}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.NoOfPCCdealersInTown && <p className={styles.MarketVisitReport__validationErrors}>{errors.NoOfPCCdealersInTown}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Estimated no of dealers to participate
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="EstimatedNoOfDealersToParticipate"
            value={formData.EstimatedNoOfDealersToParticipate}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.EstimatedNoOfDealersToParticipate && <p className={styles.MarketVisitReport__validationErrors}>{errors.EstimatedNoOfDealersToParticipate}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Dealers Head Count
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="TotalHeadCountDealer"
            value={formData.TotalHeadCountDealer}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.TotalHeadCountDealer && <p className={styles.MarketVisitReport__validationErrors}>{errors.TotalHeadCountDealer}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Employees Head Count
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="TotalHeadCountEmployee"
            value={formData.TotalHeadCountEmployee}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.TotalHeadCountEmployee && <p className={styles.MarketVisitReport__validationErrors}>{errors.TotalHeadCountEmployee}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Cost Per Head
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="CostPerHead"
            value={formData.CostPerHead}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.CostPerHead && <p className={styles.MarketVisitReport__validationErrors}>{errors.CostPerHead}</p>}
        </div>
        {showReason && (<><div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Reason for cost above 1000
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <textarea
            className="DRM_Form_text_input"
            style={{ paddingLeft: "8px", height: "3rem" }}
            name="ReasonForCost"
            value={formData.ReasonForCost}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.ReasonForCost && <p className={styles.MarketVisitReport__validationErrors}>{errors.ReasonForCost}</p>}
        </div></>)}



        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Total Cost (Read only)

          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="TotalCost"
            value={formData.TotalCost}
            onChange={handleInputChange}
            disabled={true}
            maxLength={256}
          />
          {errors.TotalCost && <p className={styles.MarketVisitReport__validationErrors}>{errors.TotalCost}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Current Monthly Average Sales Of Town
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="CurrentMonthlyAverageSalesOfTown"
            value={formData.CurrentMonthlyAverageSalesOfTown}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.CurrentMonthlyAverageSalesOfTown && <p className={styles.MarketVisitReport__validationErrors}>{errors.CurrentMonthlyAverageSalesOfTown}</p>}
        </div>
        <div className="input_field_outer Free_input">
          <label className="DRM_Form_label">
            Post DRM Monthly Average Sale Planned For The Town
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <input
            className="DRM_Form_text_input"
            type="number"
            style={{ paddingLeft: "8px" }}
            name="PostDRMMonthlyAverageSalePlannedForTheTown"
            value={formData.PostDRMMonthlyAverageSalePlannedForTheTown}
            onChange={handleInputChange}
            disabled={disable}
            maxLength={256}
          />
          {errors.PostDRMMonthlyAverageSalePlannedForTheTown && <p className={styles.MarketVisitReport__validationErrors}>{errors.PostDRMMonthlyAverageSalePlannedForTheTown}</p>}
        </div>

        <div>
          <label className={styles.MarketVisitReport__selectLabel}>
            Tentative date of the meet
            <span style={{ color: "red", fontSize: "1rem" }}>*</span>
          </label>
          <br />
          <Box className="myDatePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  height: "1.7rem",
                  marginBottom: "0.8rem",
                  border: "0.692008px solid #808080",
                  borderRadius: "0.3rem",
                  color: "#808080",
                }}
                className={styles.MarketVisitReport__dateField}
                value={formData.TentativeDateOfTheMeet}
                disabled={disable}
                minDate={mindate}
                onChange={(newValue) => handleDateChange("TentativeDateOfTheMeet", newValue)}
              />
            </LocalizationProvider>
          </Box>
          {errors.TentativeDateOfTheMeet && <p className={styles.MarketVisitReport__validationErrors}>{errors.TentativeDateOfTheMeet}</p>}

        </div>

        <div>
          <label className={styles.MarketVisitReport__selectLabel}>
            Actual Date of the Meet
          </label>
          <br />
          <Box className="myDatePicker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  height: "1.7rem",
                  marginBottom: "0.8rem",
                  border: "0.692008px solid #808080",
                  borderRadius: "0.3rem",
                  color: "#808080",
                }}
                className={styles.MarketVisitReport__dateField}
                value={formData.ActualDateOfTheMeet ? formData.ActualDateOfTheMeet : formData.TentativeDateOfTheMeet}
                disabled={disableactualdate}
                onChange={(newValue) => handleDateChange("ActualDateOfTheMeet", newValue)}
              />
            </LocalizationProvider>
          </Box>
          {errors.Zone && <p className={styles.MarketVisitReport__validationErrors}>{`Zone - ${errors.Zone}`}</p>}
          {executiondisable && (<>
            <div className={"flex p-2"}>
              <div className={`${styles.MarketVisitReport__inputWrapper}`}>
                <MdOutlineAttachment
                  style={{
                    height: "1.5em",
                    width: "1.5em",
                    color: "#2cc26a",
                    marginRight: "5px",
                  }}
                />
                Attach File
                {/* <span style={{ color: "red", fontSize: "1rem" }}>*</span> */}
                <label className={styles.MarketVisitReport_fileInputLabel}>
                  <input
                    className={`${styles.MarketVisitReport_fileInput}`}
                    type="file"
                    accept=".jpg,.png, .jpeg"
                    multiple
                    onChange={(e) => handleFileChange(e)}
                  />
                </label>
              </div>
              {selectedFiles?.length > 0 && (
                <div className={styles.MarketVisitReport_selectedFiles}>
                  {selectedFiles?.length === 1
                    ? `File: ${selectedFiles[0].name}`
                    : `Selected Files: ${selectedFiles.length}`}
                </div>
              )}

              {fileValidation && (
                <p className={styles.MarketVisitReport__validationErrors}>
                  {fileValidation}
                </p>
              )}
            </div>
            <div className={"flex p-1"}>
              <div className="file_instruction">

                (format: jpeg,png,jpg, max 5 images each below 10mb)

              </div>

            </div>


            <div className="input_field_outer Free_input">
              <label className="DRM_Form_label">
                Actual no. of dealers participated
                <span style={{ color: "red", fontSize: "1rem" }}>*</span>
              </label>
              <input
                className="DRM_Form_text_input"
                type="number"
                style={{ paddingLeft: "8px" }}
                name="ActualNoOfDealersParticipated"
                value={formData.ActualNoOfDealersParticipated}
                onChange={handleInputChange}
                disabled=""
                maxLength={256}
              />
              {errors.ActualNoOfDealersParticipated && <p className={styles.MarketVisitReport__validationErrors}>{errors.ActualNoOfDealersParticipated}</p>}
            </div>
            <div className="input_field_outer Free_input">
              <label className="DRM_Form_label">
                Actual no. of employees participated
                <span style={{ color: "red", fontSize: "1rem" }}>*</span>
              </label>
              <input
                className="DRM_Form_text_input"
                type="number"
                style={{ paddingLeft: "8px" }}
                name="ActualNoOfEmployeesParticipated"
                value={formData.ActualNoOfEmployeesParticipated}
                onChange={handleInputChange}
                disabled=""
                maxLength={256}
              />
              {errors.ActualNoOfEmployeesParticipated && <p className={styles.MarketVisitReport__validationErrors}>{errors.ActualNoOfEmployeesParticipated}</p>}
            </div>

            <div className="input_field_outer Free_input">
              <label className="DRM_Form_label">
                Actual Cost
                <span style={{ color: "red", fontSize: "1rem" }}>*</span>
              </label>
              <input
                className="DRM_Form_text_input"
                type="number"
                style={{ paddingLeft: "8px" }}
                name="ActualCost"
                value={formData.ActualCost}
                onChange={handleInputChange}
                disabled=""
                maxLength={256}
              />
              {errors.ActualCost && <p className={styles.MarketVisitReport__validationErrors}>{errors.ActualCost}</p>}
            </div>

            <div className="input_field_outer Free_input">
              <label className="DRM_Form_label">
                BM Remark
                <span style={{ color: "red", fontSize: "1rem" }}>*</span>
              </label>
              <textarea
                className="DRM_Form_text_input"
                style={{ paddingLeft: "8px", height: "3rem" }}
                name="BMRemark"
                value={formData.BMRemark}
                onChange={handleInputChange}
                disabled=""
                maxLength={256}
              />
              {errors.BMRemark && <p className={styles.MarketVisitReport__validationErrors}>{errors.BMRemark}</p>}
            </div></>)}


        </div>
        {startExecution ? (
          <button
            type="button"
            className="btn mt-3"
            style={{
              padding: "0",
              width: "100%",
              backgroundColor: "#3901FF",
              color: "white",
              fontSize: "13px",
              height: "1.8rem",
            }}
            disabled={DisableSubmitDRM}
            onClick={() => handleSpecaSubmit()}
          >
            Submit DRM <FiArrowRight style={{ marginLeft: "2px" }} />
          </button>
        ) : (
          unableupdate ? (
            <button
              type="submit"
              className="btn mt-3 drm__createMeetingButton"
              disabled={disable}
            >
              Create Meeting <FiArrowRight style={{ marginLeft: "2px" }} />
            </button>
          ) : (
            <button
              type="button"
              className="btn mt-3 drm__updateButton"
              disabled={disableUpdate}
              onClick={() => handleUpdate()}
            >
              Update <FiArrowRight style={{ marginLeft: "2px" }} />
            </button>
          )
        )}

        {isSubmitModalOpen && (
          <ModalPopup
            className="ModalPopup_Submitted"
            isModalOpen={isSubmitModalOpen}
            setIsModalOpen={setIsSubmitModalOpen}
            title="Submitted Successfully"
            data="Your DRM meeting has been created successfully."
            backdrop={true}
            fade={false}
            icon={
              <>
                <div className="submittedIcons draftIcons">
                  <FaThumbsUp className="submittedSmall" />
                  <FaThumbsUp className="submittedLarge" />
                  <FaThumbsUp className="submittedSmall" />
                </div>
              </>
            }
          />
        )}
        {isUpdateModalOpen && (
          <ModalPopup
            className="ModalPopup_Submitted"
            isModalOpen={isUpdateModalOpen}
            setIsModalOpen={setIsUpdateModalOpen}
            title="Updated Successfully"
            data="Your DRM form has been updated successfully."
            backdrop={true}
            fade={false}
            icon={
              <>
                <div className="submittedIcons draftIcons">
                  <FaThumbsUp className="submittedSmall" />
                  <FaThumbsUp className="submittedLarge" />
                  <FaThumbsUp className="submittedSmall" />
                </div>
              </>
            }
          />
        )}

        {specamodal && (
          <ModalPopup
            className="ModalPopup_Submitted"
            isModalOpen={specamodal}
            setIsModalOpen={setSpecaModal}
            title="Submitted Successfully"
            data="Your DRM form has been submitted successfully to SPECA."
            backdrop={true}
            fade={false}
            icon={
              <>
                <div className="submittedIcons draftIcons">
                  <FaThumbsUp className="submittedSmall" />
                  <FaThumbsUp className="submittedLarge" />
                  <FaThumbsUp className="submittedSmall" />
                </div>
              </>
            }
          />
        )}



      </form>
    </div>
  </div>)
}

export default DRMPlanOne;