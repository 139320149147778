import React, { useEffect } from "react";
import { connect } from "react-redux";
import Login from "../Login";
import { useIsAuthenticated } from "@azure/msal-react";

const Protected = (props) => {
  const Component = props?.component;
  const msalAuthenticated = useIsAuthenticated();
  const reduxAuthenticated = props?.isAuthenticated;
  const isAuthenticated = reduxAuthenticated;


  return <div>{isAuthenticated ? <Component /> : <Login />}</div>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state?.tokens?.isAuthenticated,
});

export default connect(mapStateToProps)(Protected);
