import React, { useState, useEffect, useRef } from "react";
import PowerBi from '../../Pages/PowerBi'
import PageHeader from '../Common/PageHeader'
import { useSelector, useDispatch } from "react-redux";

const BranchPerformanceIndex = () => {

    const [ pageId, setPageId] = useState("");
    const selectedSalesGroupCode = useSelector((state) => state?.userDetails?.details?.Sales_Group_Code__c || state?.SelectedSalesGroupCode?.SalesGroupCode );
    const userTypeAndRole = useSelector((state) => state?.userType?.type != "Other" ? state?.userType?.type : state?.userDetails?.details?.Title);

    const salesGroups = {
        105: "223cd674c97ddc2da176",
        111: "44fc726661060e5f4f3d",
        201: "7de9d97612731f282565",
        301: "89b5290e50834ee74325",
        401: "d5a214c4e18e6567daad",
        406: "e540c588abdd1363d6e1",
        901: "dde204f9b616fc883970",
        902: "25ed67a88267d3dcf2a2",
    };

    // Effect to check sales group code when it changes
    useEffect(() => {
        if (selectedSalesGroupCode) {
            if (salesGroups[selectedSalesGroupCode]) {
                setPageId(salesGroups[selectedSalesGroupCode]);
            } else {
                setPageId("");
            }
        }
    }, [selectedSalesGroupCode]);

    console.log("selectedSalesGroupCode", selectedSalesGroupCode, pageId, userTypeAndRole);

    return (
        <>
            <PageHeader title="Branch Performance Index" path="/" />
            <div className="pbi__outerBox pbi_dashboard">
                <PowerBi
                    id="c4018d9d-f992-490f-847e-b6d57e69a3f5"
                    pageName={pageId}
                />
            </div>
        </>
    )
}

export default BranchPerformanceIndex